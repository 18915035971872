import React from 'react';
import { Table } from 'antd';
import './style.scss';
import OwnPagination from '../own/OwnPagination';

export const TableGrid = ({
  columns,
  dataSource,
  pdfData,
  title,
  expandable,
  rowKey,
  loading,
  TableRef,
  pdfTableColumns,
  pagination,
  children,
  // pageSize,
  handleTableChange,
}) => {
  // function itemRender(current, type, originalElement) {
  //   if (type === 'prev') return <a>Previous</a>;

  //   if (type === 'next') return <a>Next</a>;

  //   return originalElement;
  // }

  return (
    <>
      <div className="table__wrapper">
        <div className="table__wrapper--header">{title}</div>
        <div ref={TableRef} className="table__container">
          <Table
            rowKey={rowKey}
            expandable={expandable}
            columns={columns}
            dataSource={dataSource}
            onChange={handleTableChange}
            pagination={false}
            loading={loading}
          />
          <div className="ant-pagination">{children}</div>
          {!!pagination && <OwnPagination pagination={pagination} />}
        </div>
      </div>

      <div
        className="table__wrapper"
        id="print-section"
        style={{ display: 'none' }}
      >
        <div ref={TableRef} className="table__container">
          <Table
            style={{ width: '100%', minWidth: 'unset' }}
            rowKey={rowKey}
            expandable={expandable}
            columns={pdfTableColumns ? pdfTableColumns : columns}
            dataSource={pdfData ? pdfData : dataSource}
            onChange={handleTableChange}
            pagination={false}
            loading={loading}
          />
          <OwnPagination pagination={pagination} />
        </div>
      </div>
    </>
  );
};
