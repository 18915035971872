import { message } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Api from '../../Network/ApiConfig';
import { setSplashScreenStateAction } from '../../redux/splashScreen/splashScreen.action';
import { setCurrentUser } from '../../redux/user/user.action';

const LoginSupplierById = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    const supplierId = location.pathname.replace('/supplier=', '');
    console.log(location.search.replace('/supplier=', ''));
    dispatch(setSplashScreenStateAction(true));
    Api.get(`login/data/${supplierId}`)
      .then(res => {
        dispatch(setSplashScreenStateAction(false));
        if (res.data.code === 200) {
          console.log('logged in');
          localStorage.setItem(
            'userInfo',
            JSON.stringify(res?.data?.data?.profile)
          );
          localStorage.setItem('token', `Bearer ` + res.data.message);
          dispatch(setCurrentUser(res.data.data.profile));
          setTimeout(() => {
            history.push('/');
            window.location.reload(false);
          }, 100);
        } else {
          localStorage.removeItem('token');
          dispatch(setCurrentUser(null));
          setTimeout(() => {
            history.push('/login');
            message.error('مستخدم غير موجود');
            window.location.reload(false);
          }, 100);
        }
      })
      .catch(error => {
        // history.push('/login');
        localStorage.removeItem('token');
        dispatch(setCurrentUser(null));
        setTimeout(() => {
          history.push('/login');
          window.location.reload(false);
        }, 100);
      });
  }, [dispatch, history, location.pathname, location.search]); // render() {
  return <div>by id</div>;
};

export default LoginSupplierById;
