import {
  SET_CURRENT_USER,
  SET_LANG,
  SET_META_DATA,
  SET_USER_REQUEST,
  SET_ROLE_ID,
  SET_CITIES,
  SET_CURRENT_CLIENT,
  SET_GOVERNATES,
  SET_CURRENT_SUB,
  RESET_USER,
} from './user.type';

const INITIAL_STATE = {
  currentUser: null,
  currentSub: {},
  metaData: null,
  userRequest: null,
  currentClient: null,
  governoratesList: [],
  citiesList: [],
  roleId: null,
};
const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: { ...action.payload },
      };
    case SET_CURRENT_CLIENT:
      return {
        ...state,
        currentClient: { ...action.payload },
      };
    case SET_META_DATA:
      return {
        ...state,
        metaData: { ...action.payload },
      };
    case SET_USER_REQUEST:
      return {
        ...state,
        userRequest: { ...action.payload },
      };
    case SET_LANG:
      return {
        ...state,
        currentLang: action.payload,
      };
    case SET_CITIES:
      return {
        ...state,
        citiesList: action.payload,
      };
    case SET_GOVERNATES:
      return {
        ...state,
        governoratesList: action.payload,
      };
    case SET_ROLE_ID:
      return {
        ...state,
        roleId: action.payload,
      };
    case SET_CURRENT_SUB: {
      return {
        ...state,
        currentSub: action.payload,
      };
    }

    case RESET_USER: {
      localStorage.removeItem('token');
      return {
        ...state,
        currentSub: {},
        currentUser: {},
      };
    }
    default:
      return state;
  }
};

export default userReducer;
