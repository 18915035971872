// import { useHistory, useLocation } from 'react-router-dom';
// // import { ParsedUrl } from 'query-string';
// import { useState, useEffect } from 'react';
// import queryString from 'query-string';
// import { useCallback } from 'react';

// function useSearchQuery() {
//   const { search: searchQueryStr } = useLocation();
//   const history = useHistory();

//   const [searchQueryObj, setSearchQueryObj] = useState({});

//   const getSearchObj = useCallback(() => {
//     const queryObj = queryString?.parse(searchQueryStr);
//     setSearchQueryObj(queryObj);
//   }, [searchQueryStr]);

//   useEffect(() => {
//     getSearchObj();
//   }, [getSearchObj]);

//   const setSearchQuery = queryObj => {
//     const queryStr = queryString?.stringify(queryObj);
//     history.push({ search: queryStr });
//   };

//   return { searchQueryStr, searchQueryObj, setSearchQuery };
// }

// export default useSearchQuery;

import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

function useSearchQuery() {
  const { search: searchQueryStr } = useLocation();
  const history = useHistory();

  const setSearchQuery = queryObj => {
    const queryStr = queryString?.stringify(queryObj);
    history.push({ search: queryStr });
  };

  return {
    searchQueryStr,
    searchQueryObj: queryString?.parse(searchQueryStr),
    setSearchQuery,
  };
}

export default useSearchQuery;
