import { Form, Input, Button, message } from 'antd';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Api from '../../../../Network/ApiConfig';
import './style.scss'


export const AddEditSalesTeam = () => {
  const [form] = Form.useForm();
  const location = useLocation()
  const history = useHistory();
  useEffect(() => {
    if (location.state) {
      const salesTeamData = location.state;

      form.setFieldsValue({
        username: salesTeamData.name,
        title: salesTeamData.title,
        phone: salesTeamData.phone,
        email: salesTeamData.email,
        rank: salesTeamData.rank
      });
    }
  }, [])


  const handleChangeData = (values) => {
    let header = {
      'Content-Type': 'multipart/form-data;'
    }

    let url = location.state ? 'salesTeams/update' : 'salesTeams/store'

    let body = new FormData();
    body.append("name", values.username);
    body.append("title", values.title);
    body.append("phone", values.phone);
    body.append("email", values.email);
    body.append("rank", values.rank);

    if (location.state) body.append('SalesTeamId', location.state.id)

    Api.post(url, body, header).then(res => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      } else {
        message.success('Sales Data updated successfully');
        history.push('/profile/sales-team')
      }
    })
  }

  return (
    <div className="personal__info">
      <p> {location.state ? "Edit Sales member" : "Add new Sales member"} </p>

      <Form form={form} name="personalInfo" className='form__wrapper'
        onFinish={handleChangeData}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="الاسم"
          name="username"
          rules={[{ required: true, message: 'Please input the username!' }]}
        >
          <Input placeholder='Name'/>
        </Form.Item>

        <Form.Item
          label="الوظيفة"
          name="title"
          rules={[{ required: true, message: 'Please input the Title!' }]}
        >
          <Input placeholder='Title'/>
        </Form.Item>

        <Form.Item
          label="رقم التيليفون"
          name="phone"
          rules={[{ required: true, message: 'Please input the Phone!' }]}
        >
          <Input placeholder='Phone Number' type="number" />
        </Form.Item>

        <Form.Item
          label="البريد الالكتروني"
          name="email"
          rules={[{ required: true, type: 'email', message: 'Please input the Email!' }]}
        >
          <Input placeholder='Email Address' />
        </Form.Item>


        <Form.Item
          label="ترتيب"
          name="rank"
          rules={[
            { required: true, message: 'Please input the Rank!' },
            { pattern: /^\d+$/ , message: 'must be numbers only!'}
        ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            حفظ
            <span>&#10004;</span>
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}