import React, { Component } from 'react';
import { Form, Input, Button } from 'antd';
import { userLogin } from '../../Network/authentication.network';
import { Link, withRouter } from 'react-router-dom';
import { setCurrentUser } from '../../redux/user/user.action';
import { connect } from 'react-redux';
import './style.scss';
import Logo from '../../images/login-logo.png';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      btnLoading: false,
      isLoginValid: false,
    };
  }

  componentDidMount() {}

  onFinish = values => {
    let body = new FormData();
    body.append('email', values.username);
    body.append('password', values.password);

    this.setState({ btnLoading: true });

    userLogin(
      body,
      res => {
        this.setState({ btnLoading: false });
        if (res.data.validation.length > 0) {
          this.setState({ isLoginValid: true });
        }
        if (res.data.code === 200) {
          localStorage.setItem('userInfo', JSON.stringify(res.data.data.supplier));
          localStorage.setItem('token', `Bearer ` + res.data.message);
          this.props.dispatch(setCurrentUser(res.data.data.supplier));

          // this.props.history.push('/');
          setTimeout(() => {
            this.props.history.push('/');
            // window.location.reload(false);
          }, 100);
        }
      },
      error => {
        console.log(error);
        this.setState({ btnLoading: false });
      }
    );
  };
  render() {
    const { btnLoading, isLoginValid } = this.state;
    return (
      <div className="login__wrapper">
        <div className="login__wrapper--center">
          <div className="login__logo">
            <img src={Logo} alt="Supplier" />
            <p>مرحبا بك</p>
            <p>تسجيل الدخول</p>
          </div>

          <Form name="basic" className="login__form" onFinish={this.onFinish}>
            <Form.Item
              label="البريد الالكتروني"
              name="username"
              rules={[
                {
                  required: true,
                  message: 'الرجاء إدخال اسم المستخدم الخاص بك!',
                },
              ]}>
              <Input placeholder="Emailaddress@gmail.com" />
            </Form.Item>

            <Form.Item
              label="كلمة السر"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'الرجاء إدخال كلمة المرور الخاصة بك!',
                },
              ]}>
              <Input.Password placeholder="Enter your password" />
            </Form.Item>

            {isLoginValid && (
              <p
                style={{
                  fontSize: 15,
                  color: 'red',
                  textAlign: 'center',
                  margin: '5px 0',
                }}>
                اسم مستخدم أو كلمة مرور غير صالحة
              </p>
            )}
            <Button
              style={{
                border: 0,
              }}
              type="link"
              href="/forget-password">
              Forgot password?
            </Button>

            <div className="login-btn">
              <Button type="primary" htmlType="submit" loading={btnLoading}>
                دخول
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

// const mapStateToProps = (state) => ({
//   currentLang : state.user.currentLang,
// })

const LoginWithRouter = withRouter(Login);
export default connect()(LoginWithRouter);
