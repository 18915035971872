import React, { useEffect, useMemo, useState } from 'react';
import { Button, Select, Tag, Modal, message, Form, DatePicker, Dropdown, Menu, InputNumber, Space, Row, Col } from 'antd';
import { TableGrid } from '../../../Component/Table';
import './style.scss';
import { useHistory } from 'react-router-dom';
import Api from '../../../Network/ApiConfig';
import { StarFilled, DeleteFilled, ExclamationCircleOutlined, EditFilled, EllipsisOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import Input from 'antd/lib/input/Input';
import moment from 'moment';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { useCallback } from 'react';
import useLocalPagination from '../../../hooks/useLocalPagination';
import TableSelectPagin from '../../../Component/TableSelectPagin/TableSelectPagin';
import { useUnitList } from '../../../hooks/api/master-data';

const ShipIcon = ({ ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" {...rest}>
    <g fill="none" stroke="#000" strokeMiterlimit="22.926" strokeWidth="15" clipRule="evenodd" {...rest}>
      <path
        d="M512 397.499c-26.347 0-39.953 25.697-64 25.697-21.585 0-34.76-20.702-56.287-24.941m-15.426.001c-21.527 4.239-34.702 24.941-56.287 24.941-24.047 0-37.653-25.697-64-25.697s-39.953 25.697-64 25.697c-24.046 0-37.652-25.697-64-25.697-26.347 0-39.953 25.697-64 25.697-24.046 0-37.653-25.697-64-25.697"
        data-original="#000000"></path>
      <path d="M426.974 416.764c23.268-13.434 43.368-33.831 55.026-80.463l15-59.999H354.502l-15 30H30v30l59.088 78.001" data-original="#000000"></path>
      <path
        d="M474.5 276.303v-30.001H234.502v60m213.175-60v-60H267.679v60m26.823 0v60m63.176-120v-59.999m30 59.999v60m-59.999-60v60m86.822 0v30m-59.999-30v30M60.857 246.301l7.5-59.999h97.499v59.999m-59.999-59.999v-44.999h44.999m0-60v104.999m-29.999-89.999v45"
        data-original="#000000"></path>
      <path
        d="M417.677 186.303l.001-60h-120v22.499m0 15.001v22.5m-94.323 59.998H22.5m22.5 0v60.001m135.856 0v-60.001m-59.999-29.999h15m-45.001 0h15M30 336.302h452m-32.518-30h15m-45.001 0h15"
        data-original="#000000"></path>
    </g>
  </svg>
);
const { Option } = Select;
const { confirm } = Modal;

export const AllProduct = () => {
  const [form] = Form.useForm();
  const [formExport] = Form.useForm();
  const history = useHistory();
  const [productList, setProductList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [productListPaginate, setProductListPaginate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalExportVisible, setIsModalExportVisible] = useState(false);
  const [productSelected, setProductSelected] = useState(false);
  const [areaList, setAreaList] = useState([]);
  // const [defaultCurrentVal, setDefaultCurrentVal] = useState(10);
  const [isOrderVisible, setisOrderVisible] = useState(false);
  const { searchQueryObj } = useSearchQuery();
  const { paginationNumber } = useLocalPagination();
  const [isExportProductLoading, setIsExportProductLoading] = useState(false);
  const { loading: loadingUnitList, unitList } = useUnitList();
  console.log('AllProduct  unitList:', unitList);
  const mutateProductListHelper = (id, key, func) => {
    const changedProductIdx = productList.findIndex(product => product.id === id);
    const newProductList = [...productList];
    newProductList[changedProductIdx] = {
      ...newProductList[changedProductIdx],
      [key]: func(newProductList[changedProductIdx]),
    };
    return newProductList;
  };

  const fetchProduct = useCallback(() => {
    const fd = new FormData();
    fd.append('pagination_number', searchQueryObj?.pageSize || paginationNumber);
    fd.append('page', searchQueryObj?.page || 1);

    setLoading(true);
    Api.post(`products`, fd).then(res => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        const resData = res?.data?.data;
        // console.log('Api.git  resData', resData);
        setProductList(resData?.products);
        setPagination(resData?.pagination);
        setProductListPaginate(resData);
      }
      setLoading(false);
    });
  }, [searchQueryObj.page, searchQueryObj.pageSize]);

  useEffect(() => {
    fetchProduct();
    // fetchAreaList();
  }, [fetchProduct]);

  const handleProductOrder = values => {
    setLoading(true);
    let body = new FormData();
    body.append('productId', productSelected?.id);
    body.append('order', values?.order);
    Api.post('products/assign/order', body).then(
      res => {
        console.log(res);
        setLoading(false);
        if (res.data.validation.length > 0) {
          message.error(res.data.validation[0]);
        }
        // if (res.data.code === 101) {
        // 	message.error(res.data.message);
        // }
        if (res.data.code === 200) {
          message.success(res.data.message);
          setisOrderVisible(false);
          fetchProduct();
        }
      },
      err => {
        console.log(err);
        // message.error()
      }
    );
  };

  const fetchAreaList = () => {
    // setLoading(true);
    Api.get('products/areas').then(res => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        setAreaList(res.data.data.areas);
      }
      // setLoading(false);
    });
  };

  const productSelectedMemo = useMemo(() => {
    return productSelected;
  }, [productSelected]);

  const handleDeletePromotion = (id, productId) => {
    console.log(id);
    const body = new FormData();
    body.append('promotionId', +id);

    confirm({
      title: `Delete This Promotion?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'مسح العرض',
      cancelText: 'الغاء',
      onOk: () => {
        setLoading(true);
        Api.post('products/delete/promotion', body)
          .then(res => {
            setLoading(false);
            if (res.data.validation.length > 0) {
              message.error('حدث خطأ يرجي أعادة المحاولة');
              return;
            }
            if (res.data.code === 200) {
              message.success(res.data.message);
              const newProductList = mutateProductListHelper(productId, 'promotion', () => null);
              setProductList(newProductList);
            }
          })
          .catch(() => {
            setLoading(false);
            message.error('حدث خطأ يرجي أعادة المحاولة');
          });
      },
      onCancel() {},
    });
  };
  const handleDeleteExport = id => {
    const body = new FormData();
    body.append('productExportId', id);

    confirm({
      title: `Delete This Export?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'مسح التصدير',
      cancelText: 'الغاء',
      onOk: () => {
        setLoading(true);
        Api.post('products/delete-from-export', body)
          .then(res => {
            setLoading(false);
            if (res.data.validation.length > 0) {
              message.error('حدث خطأ يرجي أعادة المحاولة');
              return;
            }
            if (res.data.code === 200) {
              message.success(res.data.message);
              fetchProduct();
            }
          })
          .catch(() => {
            setLoading(false);
            message.error('حدث خطأ يرجي أعادة المحاولة');
          });
      },
      onCancel() {},
    });
  };

  const onPromotionValuesChange = () => {
    console.log(productSelectedMemo);
    if (productSelectedMemo?.promotion) {
      form.setFieldsValue({
        promotion_price: productSelected?.promotion?.promotion_price,
        current_price: productSelected?.promotion?.current_price,
        minimum_quantity: productSelected?.promotion?.minimum_quantity,
        // endDate: moment(productSelected?.promotion?.expiration_date, 'YYYY-MM-DD'),
        // limit_of_users: productSelected?.promotion?.limit_of_users,
        // limit_of_use: productSelected?.promotion?.limit_of_use,
        // area_id: productSelected?.promotion?.area_id,
      });
    } else {
      form.setFieldsValue({
        promotion_price: '',
        current_price: '',
        minimum_quantity: '',
        // endDate: '',
        // limit_of_users: '',
        // limit_of_use: '',
        // area_id: '',
      });
    }
  };

  useEffect(() => {
    onPromotionValuesChange();
    if (productSelected) {
      console.log('useEffect  productSelected:', productSelected);
      form.setFieldsValue({ current_price: productSelected.price });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelected]);

  useEffect(() => {
    // console.log('useEffect  productSelected:', productSelected);
    if (productSelected?.exportData) {
      const unitMeasurementId =
        productSelected?.exportData?.unitMeasurementId || unitList?.find(unit => unit?.name_en === productSelected?.exportData?.unit)?.id;
      const data = { ...productSelected?.exportData, unitMeasurementId };
      formExport.setFieldsValue(data);
    } else {
      formExport.resetFields();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelected]);

  const handelChangeVisibility = data => {
    setLoading(true);
    let body = new FormData();
    body.append('productId', data?.id);
    Api.post('products/show/hide', body).then(res => {
      setLoading(false);
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        message.success(res.data.message);
        const newProductList = mutateProductListHelper(data?.id, 'visibility', prod => (prod.visibility === 1 ? 0 : 1));
        setProductList(newProductList);
      }
    });
  };

  const columns = [
    {
      title: (
        <p className="table-header-text">
          {' '}
          Order <span>الترتيب</span>{' '}
        </p>
      ),
      dataIndex: 'rank',
      render: (_, rec) => {
        return (
          <p
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setProductSelected(rec);
              setisOrderVisible(true);
            }}>
            {' '}
            {rec.rank} <StarFilled width={'20px'} style={{ color: '#ff9901' }} />{' '}
          </p>
        );
      },
    },
    {
      title: (
        <p className="table-header-text">
          {' '}
          Thumbnail <span> الصورة </span>
        </p>
      ),
      dataIndex: 'mainImage',
      render: (_, rec) => {
        return <img width={50} src={rec.mainImage} alt="product" />;
      },
    },
    {
      title: (
        <p className="table-header-text">
          {' '}
          Name <span> اسم المنتج </span>
        </p>
      ),
      dataIndex: 'name',
    },
    {
      title: (
        <p className="table-header-text">
          {' '}
          category <span> القسم </span>
        </p>
      ),
      dataIndex: 'subCategoryEnName',
    },
    {
      title: (
        <p className="table-header-text">
          {' '}
          Views <span> المشاهدات</span>
        </p>
      ),
      dataIndex: 'views',
    },
    {
      title: (
        <p className="table-header-text">
          {' '}
          Visibility <span> حالة المنتج </span>
        </p>
      ),
      dataIndex: 'visibility',
      render: (_, rec) => {
        return (
          <div>
            <Tag
              onClick={() => handelChangeVisibility(rec)}
              className="table-tag"
              style={{
                cursor: 'pointer',
                background: rec.visibility === 1 ? '#1cb5e0' : '#fc4242',
              }}>
              {rec.visibility === 1 ? 'visible - ظاهر' : 'Invisible - مخفي'}
            </Tag>
          </div>
        );
      },
    },
    {
      title: (
        <p className="table-header-text">
          {' '}
          Actions <span> افعال </span>
        </p>
      ),
      dataIndex: 'address',
      render: (_, record) => {
        return (
          <Dropdown
            trigger={['click']}
            virtual={false}
            menu={{
              items: [
                {
                  key: 'edit-product',
                  label: 'تعديل المنتج',
                  icon: (
                    <EditFilled
                      style={{
                        color: '#1cb5e0',
                        fontSize: '18px',
                      }}
                    />
                  ),
                  onClick: () => {
                    handleGetProductData(record);
                  },
                },
                {
                  key: 'delete-product',
                  label: 'مسح المنتج',
                  icon: <DeleteFilled style={{ color: '#fc4242', fontSize: '18px' }} />,
                  onClick: () => {
                    handleDeleteSubCategories(record);
                  },
                },

                !!record.promotion
                  ? [
                      {
                        key: 'edit-promotion',
                        label: 'تعديل العرض',
                        icon: (
                          <EditFilled
                            style={{
                              color: '#1cb5e0',
                              fontSize: '18px',
                            }}
                          />
                        ),
                        onClick: () => {
                          setProductSelected(record);
                          setIsModalVisible(true);
                        },
                      },
                      {
                        key: 'delete-promotion',
                        label: 'مسح العرض',
                        icon: <DeleteFilled style={{ color: '#fc4242', fontSize: '18px' }} />,
                        onClick: () => {
                          handleDeletePromotion(record?.promotion?.promotionId, record?.id);
                        },
                      },
                    ]
                  : [
                      {
                        key: 'add-promotion',
                        label: 'أضف عرض',
                        icon: (
                          <AppstoreAddOutlined
                            style={{
                              color: '#1cb5e0',
                              fontSize: '18px',
                            }}
                          />
                        ),
                        onClick: () => {
                          setProductSelected(record);
                          setIsModalVisible(true);
                        },
                      },
                    ],
                !!record.exportData
                  ? [
                      {
                        key: 'edit-promotion',
                        label: 'تعديل للتصدير',
                        icon: (
                          <EditFilled
                            style={{
                              color: '#1cb5e0',
                              fontSize: '18px',
                            }}
                          />
                        ),
                        onClick: () => {
                          setProductSelected(record);
                          setIsModalExportVisible(true);
                        },
                      },
                      {
                        key: 'delete-promotion',
                        label: 'مسح التصدير',
                        icon: <DeleteFilled style={{ color: '#fc4242', fontSize: '18px' }} />,
                        onClick: () => {
                          handleDeleteExport(record?.exportData?.id);
                        },
                      },
                    ]
                  : [
                      {
                        key: 'add-promotion',
                        label: 'أضف للتصدير',
                        icon: (
                          <ShipIcon
                            style={{
                              stroke: '#1cb5e0',
                              width: '24px',
                              height: '24px',
                            }}
                          />
                        ),
                        onClick: () => {
                          setProductSelected(record);
                          setIsModalExportVisible(true);
                        },
                      },
                    ],
              ].flat(),
            }}>
            {/* <Button icon={<EllipsisOutlined />} /> */}
            <Button type="link" icon={<MenuIcon fill="#444" width={20} height={20} />} style={{ padding: 0, border: 0, width: 36, height: 36 }} />
          </Dropdown>
        );
      },
    },
  ];

  const handleGetProductData = rec => {
    history.push({
      pathname: `/profile/product-add`,
      state: { productId: rec.id, pageNum: searchQueryObj.page },
    });
  };

  const handleDeleteSubCategories = record => {
    let body = new FormData();
    body.append('productId', record.id);

    confirm({
      title: `Delete This Product?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        Api.post('products/delete', body).then(res => {
          if (res.data.validation.length > 0) {
            message.error(res.data.validation[0]);
          }
          if (res.data.code === 200) {
            message.success(res.data.message || 'successfully');
            fetchProduct();
          }
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleProductPromotion = values => {
    const body = {
      productId: productSelected.id,
      price: values.promotion_price,
      current_price: values.current_price,
      minimum_quantity: values.minimum_quantity,
      // expiration_date: moment(values.endDate).format('YYYY-MM-DD'),
      // areaId: values.area_id,
      // limit_of_use: +values.limit_of_use,
      // limit_of_users: +values.limit_of_users,
    };
    Api.post('products/add/promotion', body).then(res => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      } else {
        message.success(res.data.message || 'successfully');
        fetchProduct();
        form.resetFields();
        const changedBodyCopy = {
          promotion_price: body.price,
          current_price: body.current_price,
          minimum_quantity: body.minimum_quantity,
          expiration_date: body.expiration_date,
          // area_id: body.areaId,
          promotionId: res.data.data.promotionId,
        };
        const newProductList = mutateProductListHelper(productSelected?.id, 'promotion', () => changedBodyCopy);
        setProductList(newProductList);
      }

      setIsModalVisible(false);
    });
  };

  const handleProductExport = values => {
    const fd = new FormData();
    fd.append('productId', productSelected.id);
    fd.append('name', values.name);
    fd.append('unitMeasurementId', values.unitMeasurementId);
    fd.append('price', values.price);

    console.log('handleProductExport  fd:', Object.fromEntries(fd));
    // return;
    setIsExportProductLoading(true);
    Api.post('products/add-to-export', fd)
      .then(res => {
        if (res.data.validation.length > 0) {
          message.error(res.data.validation[0]);
        } else {
          message.success(res.data.message || 'successfully');
          formExport.resetFields();
          fetchProduct();
        }
      })
      .finally(() => {
        setIsModalExportVisible(false);
        setIsExportProductLoading(false);
      });
  };

  const handleFilterList = value => {
    if (!!value) {
      let salesFiltered = productListPaginate?.products.filter(i => i.name.toLowerCase().includes(value.toLowerCase()));
      setProductList(salesFiltered);
    } else {
      fetchProduct();
    }
  };

  return (
    <div className="product__wrapper">
      <div className="product__wrapper--header">
        <p>العروض: {productListPaginate.promotions_count}</p>
        <p>المنتجات: {productListPaginate.products_count}</p>
      </div>
      <TableGrid
        title={
          <div className="table__filter">
            <p className="table__title">
              All products <span> كل المنتجات </span>
            </p>
            <TableSelectPagin />
            <div className="search__list--input">
              <Input placeholder="Search For Name" onChange={e => handleFilterList(e.target.value)} />
            </div>
            <div className="product__btn">
              <Button className="secondary" onClick={() => history.push('/profile/priceList')}>
                قائمة الاسعار
              </Button>
              <Button
                className="primary"
                onClick={() =>
                  history.push({
                    pathname: '/profile/product-add',
                    state: { pageNum: searchQueryObj.page },
                  })
                }>
                New Product منتج جديد
              </Button>
            </div>
          </div>
        }
        columns={columns}
        dataSource={productList}
        loading={loading}
        pagination={pagination}
      />
      <Modal
        className="add__record--modal"
        footer={null}
        title="تعديل المنتج"
        destroyOnClose
        visible={isOrderVisible}
        onOk={() => setisOrderVisible(false)}
        onCancel={() => setisOrderVisible(false)}>
        <Form
          name="personalInfow"
          className="form__wrapper"
          onFinish={handleProductOrder}
          // onFinishFailed={onFinishFailed}
          autoComplete="off">
          <Form.Item
            label="اضف قيمة"
            name="order"
            rules={[
              {
                required: true,
                message: 'This field is required!',
              },
              { pattern: /^\d+$/, message: 'must be numbers only!' },
            ]}>
            <Input type="number" />
          </Form.Item>

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              حفظ
              <span>&#10004;</span>
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Promotion Modal */}
      <Modal
        className="add__record--modal"
        footer={null}
        title="ترقية المنتج"
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}>
        <Form form={form} name="personalInfo" className="form__wrapper" onFinish={handleProductPromotion} autoComplete="off">
          <Form.Item
            label="السعر الحالي"
            name="current_price"
            // initialValue={productSelected?.price}
            rules={[
              { required: true, message: 'This field is required!' },
              { pattern: /^\d+$/, message: 'must be numbers only!' },
            ]}>
            <InputNumber controls={false} />
          </Form.Item>
          <Form.Item
            label="سعر العرض"
            name="promotion_price"
            rules={[
              { required: true, message: 'This field is required!' },
              { pattern: /^\d+$/, message: 'must be numbers only!' },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="الحد الادني للطلب"
            name="minimum_quantity"
            rules={[
              { required: true, message: 'This field is required!' },
              { pattern: /^\d+$/, message: 'must be numbers only!' },
            ]}>
            <Input />
          </Form.Item>

          {/* <Form.Item label="تاريخ نهاية العرض" name="endDate" rules={[{ required: true, message: 'This field is required!' }]}>
            <DatePicker />
          </Form.Item> */}

          <Form.Item>
            <Button type="primary" htmlType="submit">
              حفظ
              <span>&#10004;</span>
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Export Modal */}
      <Modal
        className="export-product-modal"
        footer={null}
        centered
        labelAlign="right"
        title={!!productSelected?.exportData ? 'تعديل للتصدير' : 'اضافة للتصدير'}
        open={isModalExportVisible}
        onCancel={() => {
          setIsModalExportVisible(false);
          setProductSelected(false);
        }}
        destroyOnClose>
        <Form form={formExport} labelAlign="right" layout="vertical" dir="rtl" className="form__wrappe" onFinish={handleProductExport} autoComplete="off">
          <Form.Item label="الاسم بالانجليزية" name="name" rules={[{ required: true, message: 'هذا الحقل مطلوب!' }]}>
            <Input />
          </Form.Item>

          <Form.Item label="الوحدة" name="unitMeasurementId" rules={[{ required: true, message: 'هذا الحقل مطلوب!' }]}>
            <Select
              options={unitList?.map(item => ({
                label: (
                  <Space>
                    {item?.name} - {item?.name_en}
                  </Space>
                ),
                value: item.id,
              }))}
            />
          </Form.Item>

          <Form.Item label="السعر بالدولار" name="price" rules={[{ required: true, message: 'هذا الحقل مطلوب!' }]}>
            <InputNumber prefix="USD" controls={false} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="btn" loading={isExportProductLoading}>
              حفظ
              <span>&#10004;</span>
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export const MenuIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={512}
    height={512}
    x={0}
    y={0}
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    className=""
    {...props}>
    <g>
      <rect width={24} height={2} y={11} rx={1} opacity={1} className="" /> <rect width={24} height={2} y={4} rx={1} opacity={1} className="" />
      <rect width={24} height={2} y={18} rx={1} opacity={1} className="" />
    </g>{' '}
  </svg>
);
