import {
  FETCH_GROUP_PRICE_SUCCESS,
  FETCH_GROUP_PRICE_FAIL,
} from './groupPrice.type';

const INITIAL_STATE = {
  list: [],
  loading: false,
  error: false,
};
const groupPriceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_GROUP_PRICE_SUCCESS:
      return {
        ...state,
        list: action.payload,
        error: false,
      };
    case FETCH_GROUP_PRICE_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default groupPriceReducer;
