import { Button } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import doneLogo from '../../images/done-green.svg';
import { setSplashScreenStateAction } from '../../redux/splashScreen/splashScreen.action';
import { resetUserAction } from '../../redux/user/user.action';
import './RegistrationDone.scss';

const RegistrationDone = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  console.log(location?.state?.data);
  const onResetHandler = () => {
    dispatch(setSplashScreenStateAction(true));
    setTimeout(() => {
      dispatch(setSplashScreenStateAction(false));
      dispatch(resetUserAction());
    }, 500);
  };

  return (
    <div className='registration-done-page'>
      <div className='registration-done-holder'>
        <div className='logo-holder'>
          <img src={doneLogo} alt='done logo' />
        </div>
        <div className='text-content'>
          <h2>
            {location?.state?.isCommission ? (
              <span>
                تم ارسال طلبك للتسجيل بواسطة طريقة
                <span className='color'>"Commission- based"</span>
              </span>
            ) : (
              <span>{location?.state?.data?.message}</span>
            )}
          </h2>
          <p>يمكنك الدخول لحساب المورد الخاص بك الان</p>
        </div>
        <Button type='primary' className='btn-link' onClick={onResetHandler}>
          تسجيل الدخول لحسابك
          {/* <a href="">
          </a> */}
        </Button>
      </div>
    </div>
  );
};

export default RegistrationDone;
