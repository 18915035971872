import React, { useEffect, useState } from 'react';
import { Button, Input, Form, message, Upload } from 'antd';
import './style.scss';
import Api from '../../../Network/ApiConfig';
import { WithContext as ReactTags } from 'react-tag-input';
import { ArrowLeftOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { FileImageOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import webIcon from '../../../images/world.png';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUser } from '../../../redux/user/user.action';

export const CompanyInfo = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state?.user?.currentUser);
  const [form] = Form.useForm();
  const [productTags, setProductTags] = useState([]);
  const [supplierId, setSupplierId] = useState('');
  const [imgList, setImgList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setloading] = useState(false);

  console.log(imgList, 'img');
  const handleChangeData = values => {
    console.log('values', values);
    if (imgList.length) {
      let tags = [];
      productTags?.forEach(tag => {
        tags.push(tag.text);
      });

      const body = new FormData();

      body.append('supplierName', values.name);
      body.append('supplierPhone', values.phone);
      body.append('supplierEmail', values.email);
      body.append('address', values.address);
      body.append('website', values.website);
      body.append('description', values.description);
      body.append('keywords', tags.join(' , '));
      body.append('socialLinks[facebook]', values.facebook);
      body.append('socialLinks[linkedin]', values.linkedin);
      body.append('socialLinks[instagram]', values.insta);
      // body.append('socialLinks[whatsApp]', values.whatsApp);

      body.append('supplierId', supplierId);
      if (imgList[0].originFileObj) {
        body.append('logo', imgList[0].originFileObj);
      }

      // let body = {
      //   supplierName: values.name,
      //   supplierPhone: values.phone,
      //   supplierEmail: values.email,
      //   address: values.address,
      //   website: values.website,
      //   description: values.description,
      //   keywords: tags.join(' , '),
      //   // longitude: values.longitude,
      //   // latitude: values.location,
      //   socialLinks: {
      //     facebook: socialLinks.facebook,
      //     linkedin: socialLinks.linkedin,
      //     instagram: socialLinks.insta,
      //   },
      //   supplierId: supplierId,
      //   image: imgList[0].originFileObj,
      // };
      setBtnLoading(true);
      Api.post('profile/update', body).then(res => {
        if (res.data.validation.length > 0) {
          message.error(res.data.validation[0]);
        } else {
          //   image: imgList[0].originFileObj,
          console.log('logo', imgList[0].originFileObj);
          dispatch(
            setCurrentUser({
              ...currentUser,
              ...res.data?.data?.supplier,
            })
          );
          message.success(res.data.message || 'successfully');
        }

        setBtnLoading(false);
      });
    } else {
      message.error('You have to add an image');
    }
  };

  const handleDelete = i =>
    setProductTags(productTags.filter((tag, index) => index !== i));

  const handleAddition = tag => setProductTags([...productTags, tag]);

  useEffect(() => {
    handleFetchProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = async ({ fileList }) => setImgList(fileList);

  const handleFetchProfileData = () => {
    setloading(true);
    Api.get('profile').then(res => {
      setloading(false);
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        const data = res.data.data.profile;
        // console.log('Api.get  data:', data);

        let tags = data?.keywords?.split(',');
        if (tags?.length) {
          const localTags = tags?.map(tag => {
            return { text: tag, id: tag };
          });
          setProductTags(localTags || []);
        }

        setSupplierId(data.id);

        const images = {
          uid: 'iid',
          name: data?.logo.split('/')[data?.logo.split('/').length - 1],
          status: 'done',
          url: data?.logo,
          thumbUrl: data?.logo,
        };
        setImgList([images]);
        form.setFieldsValue({
          name: data?.company,
          email: data?.supplierEmail,
          phone: data?.supplierPhone,
          address: data?.address,
          website: data?.website,
          description: data?.description,
          facebook: data.socialLinks?.facebook || '',
          insta: data.socialLinks?.instagram || '',
          linkedin: data.socialLinks?.linkedin || '',
          // whatsApp: data.socialLinks?.whatsApp || '',
        });
      }
    });
  };

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const uploadButton = (
    <p className="image__upload--btn">
      <FileImageOutlined />{' '}
    </p>
  );

  return (
    <div className="content__wrapper">
      <div className="product__wrapper--add">
        <p className="product__title--page">
          Edit My Profile - تعديل الحساب الشخصي
        </p>
        <Form
          form={form}
          name="personalInfo"
          className="form__wrapper form__wrapper--product"
          onFinish={handleChangeData}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="" style={{ flexBasis: '100%' }} name="imgs">
            <ImgCrop quality={0.1}>
              <Upload
                action={file => {}}
                accept="image/png, image/gif, image/jpeg"
                listType="picture-card"
                fileList={imgList}
                onChange={handleChange}
                onPreview={onPreview}
                maxCount={1}
              >
                {imgList.length >= 8 ? null : uploadButton}
              </Upload>
            </ImgCrop>
          </Form.Item>

          <Form.Item
            // label='اسم الشركة'
            name="name"
            rules={[{ required: true, message: 'This Field is required!' }]}
          >
            <Input placeholder="اسم الشركة" />
          </Form.Item>

          <Form.Item
            // label='تيلفون الشركة'
            name="phone"
            rules={[{ required: true, message: 'This Field is required!' }]}
          >
            <Input placeholder="تيلفون الشركة" />
          </Form.Item>

          <Form.Item
            // label='بريد الشركة الالكتروني'
            name="email"
            rules={[{ required: true, message: 'This Field is required!' }]}
          >
            <Input placeholder="بريد الشركة الالكتروني" />
          </Form.Item>

          <Form.Item
            // label='عنوان الشركة'
            name="address"
            rules={[{ required: true, message: 'This Field is required!' }]}
          >
            <Input placeholder="عنوان الشركة" />
          </Form.Item>
          <Form.Item
            // label='وصف الشركة'
            style={{ flexBasis: '100%' }}
            name="description"
            // className=`'rtl-label'
            className="label-rtl"
          >
            <TextArea
              placeholder="وصف الشركة"
              autoSize={{ minRows: 4, maxRows: 5 }}
            />
          </Form.Item>

          {/* <Form.Item
            label='الموقع الجغرافي (latitude)'
            name='latitude'
            className='label-rtl'
            rules={[{ required: true, message: 'This Field is required!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='الموقع الجغرافي (longitude)'
            name='longitude'
            className='label-rtl'
            rules={[{ required: true, message: 'This Field is required!' }]}
          >
            <Input />
          </Form.Item> */}

          <h2 className="social_links-title"> Supplier Social Links </h2>

          <div className="social__media--fields">
            <span className="price__label">
              <img src={webIcon} alt="video" />
            </span>
            <Form.Item
              name="website"
              // rules={[{ required: true, message: 'This Field is required!' }]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className="social__media--fields">
            <span className="price__label">
              <img src="/images/companyFacebook.svg" alt="video" />
            </span>
            <Form.Item label="" name="facebook">
              <Input />
            </Form.Item>
          </div>

          <div className="social__media--fields">
            <span className="price__label">
              <img src="/images/linkedin.svg" alt="video" />
            </span>
            <Form.Item label="" name="linkedin">
              <Input />
            </Form.Item>
          </div>

          <div className="social__media--fields">
            <span className="price__label">
              <img src="/images/insta.svg" alt="video" />
            </span>
            <Form.Item label="" name="insta">
              <Input />
            </Form.Item>
          </div>

          {/* <div className="social__media--fields">
            <span className="price__label">
              <img src="/images/whatsapp.svg" alt="whatsapp" />
            </span>
            <Form.Item label="" name="whatsApp">
              <Input />
            </Form.Item>
          </div> */}

          <Form.Item
            label="*كلمات البحث عن المورد"
            style={{ flexBasis: '100%' }}
          >
            <div className="product__tags">
              <ReactTags
                autofocus={false}
                tags={productTags}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                placeholder=""
              />
            </div>
          </Form.Item>

          <Form.Item
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexBasis: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexBasis: '100%',
              }}
            >
              <Button
                loading={btnLoading}
                onClick={() =>
                  setTimeout(() => {
                    document
                      .querySelector('.ant-form-item-has-error')
                      ?.scrollIntoView(false);
                  }, 50)
                }
                htmlType="submit"
                style={{ border: 'none', color: '#001a62' }}
              >
                {' '}
                حفظ{' '}
              </Button>
              <Button
                loading={btnLoading}
                onClick={() =>
                  setTimeout(() => {
                    document
                      .querySelector('.ant-form-item-has-error')
                      ?.scrollIntoView(false);
                  }, 50)
                }
                htmlType="submit"
                style={{ background: '#001a62', color: '#fff' }}
              >
                {' '}
                التالي <ArrowLeftOutlined />{' '}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
