import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Dropdown, Input, message, Select } from 'antd';
import { TableGrid } from '../../../Component/Table';
import './style.scss';
import Api from '../../../Network/ApiConfig';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { DownOutlined } from '@ant-design/icons';
import { StyleToPRint } from '../../../Component/jsonsStyles/styleJson';
import { decrementDashbaordCounterAction } from '../../../redux/dashboard/dashboard.action';
import { DB_CALLBACK_KEY } from '../../../redux/dashboard/dashboard.type';
import { useDispatch } from 'react-redux';
import TableSelectPagin from '../../../Component/TableSelectPagin/TableSelectPagin';
import useSearchQuery from '../../../hooks/useSearchQuery';
import useLocalPagination from '../../../hooks/useLocalPagination';

const CheckboxGroup = Checkbox.Group;

const columns = [
  {
    title: <p className="table-header-text"> id </p>,
    dataIndex: 'id',
  },
  {
    title: (
      <p className="table-header-text">
        {' '}
        Name <span> الاسم </span>
      </p>
    ),
    dataIndex: 'user_name',
  },
  {
    title: (
      <p className="table-header-text">
        {' '}
        phone <span> الموبايل </span>
      </p>
    ),
    dataIndex: 'user_phone',
  },
  {
    title: (
      <p className="table-header-text">
        {' '}
        Email <span> البريد الالكتروني </span>
      </p>
    ),
    dataIndex: 'user_email',
  },
  {
    title: (
      <p className="table-header-text">
        {' '}
        Company Name <span> اسم الشركة </span>
      </p>
    ),
    dataIndex: 'company_name',
  },
  {
    title: (
      <p className="table-header-text">
        {' '}
        Date <span> التاريخ </span>
      </p>
    ),
    dataIndex: 'date',
    render: (_, rec) => {
      return <p> {moment(rec?.date).format('DD/MM/YYYY')} </p>;
    },
  },
];

export const CallBack = () => {
  const pdfRef = React.createRef();
  const [callBack, setCallBack] = useState([]);
  const [callBackList, setCallBackList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultCurrentVal, setDefaultCurrentVal] = useState(10);
  const [tableColumn, setTableColumn] = useState(columns);
  const [excelData, setExcelData] = useState([]);

  const [checkedList, setCheckedList] = useState(
    columns.map(item => {
      return item.dataIndex;
    })
  );
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(true);
  const dispatch = useDispatch();
  const { searchQueryObj } = useSearchQuery();
  const { paginationNumber } = useLocalPagination();

  const checkBoxColumn = columns.map(item => {
    return { label: item.title, value: item.dataIndex };
  });

  const onChange = list => {
    let tableColumnClone = [];
    columns.map(item => {
      if (list.includes(item.dataIndex)) {
        tableColumnClone.push(item);
      }
    });

    setTableColumn(tableColumnClone);
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < checkBoxColumn.length);
    setCheckAll(list.length === checkBoxColumn.length);
  };

  const onCheckAllChange = e => {
    setTableColumn(e.target.checked ? columns : []);
    setCheckedList(
      e.target.checked
        ? columns.map(item => {
            return item.dataIndex;
          })
        : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const fetchCallbacks = useCallback(() => {
    const fd = new FormData();
    fd.append(
      'pagination_number',
      searchQueryObj?.pageSize || paginationNumber
    );
    fd.append('page', searchQueryObj?.page || 1);
    setLoading(true);
    Api.post('reports/call-back', fd).then(res => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        setCallBack(res.data.data);
        setCallBackList(res.data.data?.call_backs);
        dispatch(decrementDashbaordCounterAction(DB_CALLBACK_KEY));
      }
      setLoading(false);
    });
  }, [searchQueryObj.page, searchQueryObj.pageSize]);

  useEffect(() => {
    fetchCallbacks();
  }, [fetchCallbacks]);

  useEffect(() => {
    const data = callBack?.call_backs?.map(item => {
      let obj = {};
      tableColumn.map(dataIndex => {
        if (dataIndex.dataIndex === 'date')
          return (obj[dataIndex?.title?.props?.children[1]?.trim()] = moment(
            item[dataIndex.dataIndex]
          ).format('DD/MM/YYYY'));
        else if (!Array.isArray(item[dataIndex?.dataIndex])) {
          const currentObjChild = dataIndex?.title?.props?.children;
          const newObjKey = Array.isArray(currentObjChild)
            ? dataIndex?.title?.props?.children[1]?.trim()
            : dataIndex?.title?.props?.children?.trim();
          return (obj[newObjKey] = item[dataIndex.dataIndex] ?? 'لا يوجد');
        }
      });
      return obj;
    });
    setExcelData(data);
  }, [tableColumn, callBack]);

  const columnList = () => {
    return (
      <div className="dropdown__column--visible">
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          Check all
        </Checkbox>
        <CheckboxGroup
          options={checkBoxColumn}
          value={checkedList}
          onChange={onChange}
        />
      </div>
    );
  };

  const handleFilterList = value => {
    if (!!value) {
      let salesFiltered = callBackList?.filter(i =>
        i.user_name.toLowerCase().includes(value.toLowerCase())
      );
      setCallBack({
        ...callBack,
        call_backs: salesFiltered,
      });
    } else {
      fetchCallbacks();
    }
  };

  const handelPrint = () => {
    var bodyPrint = document.getElementById('print-section').innerHTML;
    var mywindow = window.open('', 'PRINT');
    mywindow.document.write('<html><head>');
    mywindow.document.write(
      '<link href="https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap" rel="stylesheet">'
    );
    mywindow.document.write(`<style> ${StyleToPRint}</style>`);
    mywindow.document.write(bodyPrint);
    mywindow.document.write('</body></html>');
    mywindow.document.title = 'Call Back';
    setTimeout(() => {
      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      mywindow.close();
    }, 200);
  };

  return (
    <div className="product__wrapper">
      <TableGrid
        TableRef={pdfRef}
        title={
          <div className="table__filter">
            <p className="table__title">
              {' '}
              Call Back <span> طلب الاتصال </span>
            </p>
            <div className="download__list">
              <CSVLink
                filename="call-back.csv"
                // onClick={(event, done) => {
                //   Api.post('reports/call-back').then((res) => {
                //     setLoading(true)
                //     if (res.data.validation.length > 0) {
                //       message.error(res.data.validation[0]);
                //     }
                //     if (res.data.code === 200) {
                //       setCallBack(res.data.data);
                //     }
                //   });
                // }}
                data={excelData || []}
              >
                Excel
              </CSVLink>
              <a onClick={handelPrint}>Pdf</a>
              <Dropdown trigger="click" overlay={columnList}>
                <a style={{ width: '135px' }}>
                  column visibility{' '}
                  <DownOutlined style={{ marginLeft: '7px' }} />
                </a>
              </Dropdown>
            </div>

            <TableSelectPagin />
            <div className="search__list--input">
              <Input
                placeholder="Search For Name"
                onChange={e => handleFilterList(e.target.value)}
              />
            </div>
          </div>
        }
        columns={tableColumn}
        dataSource={tableColumn.length > 0 ? callBack?.call_backs : []}
        loading={loading}
        pagination={callBack?.pagination}
        // pagination={{
        //   pageSize: defaultCurrentVal,
        //   total: callBack?.call_backs?.length,
        // }}
        // defaultCurrent={defaultCurrentVal}
        // pageSize={defaultCurrentVal}
        // total={callBack?.pagination?.total}
        // onChange={pagination => {
        //   let url = `reports/call-back?page=${pagination?.current}`;
        //   fetchCallbacks(url);
        // }}
      />
    </div>
  );
};
