import { combineReducers } from 'redux';
import userReducer from './user/user.reducer';
import cartReducer from './cart/cart.reducer';
import orderReducer from './order/order.reducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import dashboardReducer from './dashboard/dashboard.reducer';
import splashScreenReducer from './splashScreen/splashScreen.reducer';
import groupPriceReducer from './groupPrice/groupPrice.redcuer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart', 'user'],
};

const rootReducer = combineReducers({
  user: userReducer,
  groupPrice: groupPriceReducer,
  cart: cartReducer,
  order: orderReducer,
  dashboard: dashboardReducer,
  splashScreen: splashScreenReducer,
});

export default persistReducer(persistConfig, rootReducer);
