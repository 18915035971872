import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import NoSubImg from '../../images/rfq home.svg';
import './NoSubscription.scss';

const NoSubscription = () => {
  const history = useHistory();
  return (
    <div className='wrapper'>
      <div className='content'>
        <img className='content__img' src={NoSubImg} alt='no sub' />
        <div className='content__text-wrapper'>
          <h1 className='content__title'>لا يوجد أشتراك</h1>
          <p className='content__text'>
            يجب ان يكون لديك اشتراك لتتمكن من استخدام نظامنا
          </p>
        </div>
        <Button
          className='primary content__btn btn-next'
          onClick={() =>
            history.push({
              pathname: '/plans',
              state: null,
            })
          }
        >
          أشترك
        </Button>
      </div>
    </div>
  );
};

export default NoSubscription;
