import { Layout, Menu, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import './style.scss';
import { useHistory } from 'react-router-dom';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import Api from '../../../Network/ApiConfig';
import {
  fetchDashboardDataAction,
  fetchDashboardDataSuccessAction,
} from '../../../redux/dashboard/dashboard.action';
import { setSplashScreenStateAction } from '../../../redux/splashScreen/splashScreen.action';
import { fetchGroupPriceSuccessAction } from '../../../redux/groupPrice/groupPrice.action';
import useLayout from '../../../hooks/useLayout';

const { SubMenu } = Menu;
const { Sider } = Layout;

const rootSubmenuKeys = [
  'Dashboard',
  'Profile',
  'Reports',
  '2',
  'Subscription',
  'Orders',
];

export const SideBar = ({ noMenu }) => {
  const dashboardData = useSelector(state => state?.dashboard);
  const [collapsed, setCollapsed] = useState(false);
  const [openKeysState, setOpenKeys] = useState(['Dashboard']);
  const { isMidScr } = useLayout();

  useEffect(() => {
    if (isMidScr) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, [isMidScr]);
  const toggle = e => {
    console.log(e);
    e.stopPropagation();
    setCollapsed(!collapsed);
  };
  const history = useHistory();
  const { pathname } = useLocation();
  const handleRouteToComponent = route => history.push(route);

  useEffect(() => {
    if (document.getElementsByClassName('ant-layout-content')[0])
      document.getElementsByClassName('ant-layout-content')[0].scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    // fetchHomeData();
    fetchGroupPriceList();
  }, []);

  const dispatch = useDispatch();

  const fetchGroupPriceList = () => {
    let body = {
      pagination_number: 100,
    };

    Api.post('groups/get', body).then(res => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        dispatch(fetchGroupPriceSuccessAction(res.data.data.my_groups));
      }
    });
  };

  // const calculateAllReportNumber = useMemo(() => {
  //   let result = 0;
  //   const stats = dashboardData.Statistics;
  //   if (dashboardData.Statistics) {
  //     result =
  //       stats.leads +
  //       stats.uniqueLeads +
  //       stats.viewInfo +
  //       stats.callBacks +
  //       stats.productRFQs +
  //       stats.generalRFQs;
  //   }
  //   return result > 100 ? '+999' : result;
  // }, [dashboardData?.Statistics]);

  const onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(
      key => openKeysState.indexOf(key) === -1
    );

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) setOpenKeys(openKeys);
    else setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  const handleCloseSubMenu = openKey => setOpenKeys([openKey]);

  return (
    <Sider
      className={collapsed ? 'collapsed' : 'open'}
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      <div className="logo__wrapper" onClick={() => history.push('/')}>
        <p>
          {collapsed ? (
            <img src="/images/smallLogo.jpeg" alt="Logo" />
          ) : (
            <img src="/images/logo.png" alt="logo" />
          )}
        </p>
        <span className="collapse-icon" onClick={toggle}>
          {/* <span className='collapse-icon' > */}
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </span>
      </div>
      {noMenu ? (
        <></>
      ) : (
        <Menu
          openKeys={openKeysState}
          onOpenChange={onOpenChange}
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['1']}
        >
          <Menu.Item
            onClick={() => {
              handleRouteToComponent('/dashboard');
              handleCloseSubMenu('Dashboard');
            }}
            key="Dashboard"
            icon={<img src="/images/leftMenu/dashboard.svg" alt="Dashboard" />}
          >
            <p>
              Dashboard <span>لوحة التحكم</span>
            </p>
          </Menu.Item>
          <SubMenu
            key="Profile"
            icon={<img src="/images/leftMenu/profile.svg" alt="Profile" />}
            title={
              <p>
                Profile <span>الحساب</span>{' '}
              </p>
            }
          >
            <Menu.Item
              onClick={() => handleRouteToComponent('/profile/company-info')}
              key="companyInfo"
            >
              <p>
                Company info <span>بيانات الشركة</span>
              </p>
            </Menu.Item>
            <Menu.Item
              onClick={() => handleRouteToComponent('/profile/sub-categories')}
              key="Subcategories"
            >
              <p>
                {' '}
                Subcategories <span>الاقسام</span>
              </p>
            </Menu.Item>
            <Menu.Item
              onClick={() => handleRouteToComponent('/profile/sales-team')}
              key="salesTeam"
            >
              <p>
                Sales Team <span>فريق المبيعات</span>
              </p>
            </Menu.Item>
            <Menu.Item
              onClick={() => handleRouteToComponent('/profile/all-product')}
              key="Products"
            >
              <p>
                Products <span>المنتجات</span>
              </p>
            </Menu.Item>
            {/* <Menu.Item
            onClick={() => handleRouteToComponent('/profile/newsFeed')}
            key='Newsfeeds'
          >
            <p>
              Newsfeeds <span>المنشورات</span>
            </p>
          </Menu.Item> */}
            <Menu.Item
              onClick={() => handleRouteToComponent('/profile/userGroup')}
              key="userGroup"
            >
              <p>
                Min order <span>الحد الادني للطلب</span>
              </p>
            </Menu.Item>
          </SubMenu>

          <SubMenu
            key="Reports"
            icon={<img src="/images/leftMenu/reports.svg" alt="Reports" />}
            className="sidebar-reports"
            title={
              <p>
                Reports <span>التقارير</span>{' '}
                <span className="sidebar-reports-number">
                  {dashboardData?.Statistics?.counters?.all}
                </span>
              </p>
            }
          >
            <Menu.Item
              className="sidebar-reports-subMenuItem"
              onClick={() => handleRouteToComponent('/reports/allLeads')}
              key="AllLeads"
            >
              <p>
                All Leads <span>كل العملاء</span>
                {/* <span className='sidebar-reports-number'>
                {dashboardData?.Statistics.leads}
              </span> */}
              </p>
            </Menu.Item>
            <Menu.Item
              onClick={() => handleRouteToComponent('/reports/userLeads')}
              key="Unique"
              className="sidebar-reports-subMenuItem"
            >
              <p>
                Unique Leads <span>العملاء الجدد</span>
                <span className="sidebar-reports-number">
                  {dashboardData?.Statistics?.counters?.newLeadsCount}{' '}
                </span>
              </p>
            </Menu.Item>
            <Menu.Item
              onClick={() => handleRouteToComponent('/reports/info-view')}
              key="Viewed"
              className="sidebar-reports-subMenuItem"
            >
              <p>
                {' '}
                Info Viewed <span>اظهار البيانات</span>
                <span className="sidebar-reports-number">
                  {dashboardData?.Statistics.counters?.viewInfo}
                </span>
              </p>
            </Menu.Item>
            <Menu.Item
              onClick={() => handleRouteToComponent('/reports/callBack')}
              key="Call"
              className="sidebar-reports-subMenuItem"
            >
              <p>
                Call back <span>طلب الاتصال</span>
                <span className="sidebar-reports-number">
                  {dashboardData?.Statistics?.counters?.callBack}
                </span>
              </p>
            </Menu.Item>
            <Menu.Item
              onClick={() => handleRouteToComponent('/reports/product')}
              key="RFQ"
              className="sidebar-reports-subMenuItem"
            >
              <p>
                Product RFQ <span>طلب سعر المنتج</span>
                <span className="sidebar-reports-number">
                  {' '}
                  {dashboardData?.Statistics?.counters?.productRfq}{' '}
                </span>
              </p>
            </Menu.Item>
            <Menu.Item
              onClick={() => handleRouteToComponent('/reports/generalRfq')}
              key="GeneralRFQ"
              className="sidebar-reports-subMenuItem"
            >
              <p>
                General RFQ <span>طلبات الاسعار</span>
                <span className="sidebar-reports-number">
                  {' '}
                  {dashboardData?.Statistics?.counters?.generalRfq}{' '}
                </span>
              </p>
            </Menu.Item>
          </SubMenu>

          <Menu.Item
            onClick={() => {
              handleRouteToComponent('/messages');
              handleCloseSubMenu('Message');
            }}
            key="Message"
            icon={<img src="/images/leftMenu/Message.svg" alt="Message" />}
          >
            <p>
              Messages <span>الرسائل</span>
              <span className="sidebar-reports-number">
                {' '}
                {dashboardData?.Statistics?.messages?.unread}{' '}
              </span>
            </p>
          </Menu.Item>

          <SubMenu
            key="Subscription"
            icon={
              <img
                src="/images/leftMenu/subscriptions.svg"
                alt="Subscriptions"
              />
            }
            title={
              <p>
                Subscription <span>الاشتراك</span>{' '}
              </p>
            }
          >
            <Menu.Item
              onClick={() =>
                handleRouteToComponent('/subscription/mySubscription')
              }
              key="mySubscription"
            >
              <p>
                My Subscription <span>اشتراكي</span>
              </p>
            </Menu.Item>
            {/* <Menu.Item onClick={() => handleRouteToComponent("/subscription/purchase")} key="Extras">
						<p> Extras <span>باقات اضافية</span></p>
					</Menu.Item> */}
            <Menu.Item
              onClick={() => handleRouteToComponent('/subscription/history')}
              key="History"
            >
              <p>
                History <span>تفاصيل المعاملات</span>
              </p>
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                handleRouteToComponent('/subscription/my-history-sub')
              }
              key="SubscriptionHistory"
            >
              <p>
                <span style={{ lineHeight: '16px' }}>
                  {' '}
                  Subscription <br /> History{' '}
                </span>
                <span>الاشتراكات السابقة</span>
              </p>
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            onClick={() => {
              handleRouteToComponent('/order');
              handleCloseSubMenu('Orders');
            }}
            key="Orders"
            icon={<img src="/images/leftMenu/order.svg" alt="Order" />}
          >
            <p>
              Orders <span>الطلبات</span>
              <span className="sidebar-reports-number">
                {' '}
                {dashboardData?.Statistics?.counters?.orderCount}{' '}
              </span>
            </p>
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              handleRouteToComponent('/tenders');
              handleCloseSubMenu('Tenders');
            }}
            key="Tenders"
            icon={<img src="/images/leftMenu/tender.svg" alt="tender" />}
          >
            <p>
              Tenders <span>المناقصات</span>
              <span className="sidebar-reports-number">
                {' '}
                {dashboardData?.Statistics?.counters?.tenderCount}{' '}
              </span>
            </p>
          </Menu.Item>
          <Menu.Item
            onClick={() => handleRouteToComponent('/profile/newsFeed')}
            key="Newsfeeds"
            icon={<img src="/images/leftMenu/reports.svg" alt="Reports" />}
          >
            <p>
              Newsfeeds <span>المنشورات</span>
            </p>
          </Menu.Item>
        </Menu>
      )}
    </Sider>
  );
};
