import {
  DECREMENT_DASHBOARD_DATA_COUNTER,
  FETCH_DASHBOARD_DATA,
  FETCH_DASHBOARD_DATA_ERROR,
  FETCH_DASHBOARD_DATA_SUCCESS,
} from './dashboard.type';

const INITIAL_STATE = {
  Statistics: {},
  charts: [],
  error: false,
  loading: false,
};

const dashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_DATA: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case FETCH_DASHBOARD_DATA_SUCCESS:
      return {
        loading: false,
        error: false,
        Statistics: action?.payload?.Statistics,
        charts: action?.payload?.charts,
      };
    case FETCH_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        error: action?.payload,
        loading: false,
      };
    case DECREMENT_DASHBOARD_DATA_COUNTER: {
      // console.log('sheeesh', state?.Statistics?.counters?.[action?.payload]);
      return {
        ...state,
        Statistics: {
          ...state?.Statistics,
          counters: {
            ...state?.Statistics?.counters,
            all: state?.Statistics?.counters?.[action?.payload]
              ? state?.Statistics?.counters?.all -
                state?.Statistics?.counters?.[action?.payload]
              : 0,
            [action?.payload]: 0,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default dashboardReducer;
