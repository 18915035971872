import { Button, Col, message, Row, Spin, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import Package from '../../Component/Package/Package';
import './Plans.scss';
import { useHistory, useLocation } from 'react-router-dom';
import Api, { websiteApiUrl } from '../../Network/ApiConfig';

const { TabPane } = Tabs;
const Plans = () => {
  const history = useHistory();
  const [selectPackge, setSelectPackge] = useState(null);
  const [loading, setloading] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const location = useLocation();

  const [subscriptionsLists, setSubscriptionsLists] = useState([]);
  console.log('Plans  subscriptionsLists:', subscriptionsLists);

  const getSubscriptionsLists = () => {
    setloading(true);
    Api.get(`subscriptions`)
      .then(res => {
        setloading(false);
        if (res.data.validation.length > 0) {
          message.error(res.data.validation[0]);
        }
        if (res.data.code === 200) {
          console.log(res.data);
          setSubscriptionsLists(res.data.data.subscriptions);
        }
      })
      .catch(() => {
        setloading(false);
        message.error('Error, Please try again!');
      });
  };

  useEffect(() => {
    getSubscriptionsLists();
  }, []);

  const handelfifthStepRegister = () => {
    setloading(true);
    let body = new FormData();
    body.append('supplierId', location?.state?.data?.supplier_id);
    body.append('paymentTypeId', 1);
    console.log(activeTab);
    if (+activeTab === 1) {
      if (!selectPackge) {
        message.error('يجب اختيار ياقه اولا');
        setloading(false);
        return false;
      }
      body.append('subscriptionId', selectPackge);
      history.push({
        pathname: `/choose-payment`,
        state: {
          data: {
            supplier_id: location?.state?.data?.supplier_id,
            subscriptionId: selectPackge,
          },
        },
      });
      setloading(false);
      return false;
    } else {
      body.append('subscriptionId', ' ');
    }
    Api.post('fifthStepRegister', body).then(res => {
      if (res?.data?.validation?.length > 0) {
        setloading(false);
        res?.data?.validation?.forEach(err => message.error(err));
      } else {
        setloading(false);
        if (res.data.code === 200) {
          message.success(res.data?.data?.message);
          // window.open("http://64.227.117.9/","_self");
          history.push({
            pathname: `/supplier/registration-done`,
            state: { data: res?.data?.data, isCommission: true },
          });
        }
      }
    });
  };

  return (
    <div className="reg-subscription-hero">
      <div className="reg-subscription-holder">
        <div className="reg-subscription-content">
          <h2 className="title">اختر طريقة التسجيل</h2>
          <div className="tabs-holder">
            <Tabs activeKey={activeTab} onChange={key => setActiveTab(key)}>
              <TabPane tab="باقات الاشتراك" key="1">
                <div className="subscription-packages-holder">
                  <div className="subscription-packages-content">
                    <h2 className="subscription-packages-title">اختر الباقة المناسبة لك</h2>
                    <div className="packages-holderx">
                      {/* <Row gutter={[20, 40]} justify='center'> */}
                      {loading ? (
                        <div
                          style={{
                            minHeight: '50vh',
                            maxWidth: '90vw',
                            marginInline: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <Spin size="large" />
                        </div>
                      ) : (
                        <Row gutter={[20, 40]} justify="center" wrap={true} style={{ maxWidth: '90vw' }}>
                          {subscriptionsLists?.map(sub => {
                            return (
                              <Col key={sub?.id}>
                                <div style={{ width: '260px', maxWidth: '100%', margin: '0 auto' }}>
                                  <Package
                                    selectPackge={selectPackge}
                                    setSelectPackge={setSelectPackge}
                                    name={sub?.name}
                                    price={sub?.price}
                                    online_shop={sub?.online_shop}
                                    multiple_pricing={sub?.multiple_pricing}
                                    sales_commission={sub?.sales_commission}
                                    products={sub?.products}
                                    customers={sub?.clients || 0}
                                    // tenders={sub?.tenders}
                                    // sections={sub?.categories}
                                    // googleBudget={sub?.googleBudget}
                                    // facebookBudget={sub?.facebookBudget}
                                    // emails={sub?.emails}
                                    // promotions={sub?.promotions}
                                    // durationInMonth={sub?.durationInMonth}
                                    id={sub?.id}
                                  />
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      )}
                      {/* </Row> */}
                    </div>
                  </div>
                </div>
              </TabPane>

              {/* <TabPane tab="نظام العمولة" key="2">
                <div className="commission-based-holder">
                  <div className="commission-based-content">
                    <h2 className="commission-based-title">
                      <span>تسجيل عن طريقة</span>
                      <span className="color">نظام العمولة</span>
                    </h2>
                    <div className="commission-based-text">
                      <p>
                      نظام العمولة يوفر لكم امكانية بيع  منتجات شركتكم المختلفه من خلال الموقع مقابل نسبة عمولة تحددها الشركة و يتم تحديدها حسب نوع النشاط و سوف يقوم احد ممثلي الشركة بالتواصل معكم لتزويدكم بباقي المعلومات
                      </p>
                    </div>
                  </div>
                </div>
              </TabPane> */}
            </Tabs>
          </div>
          <Button loading={loading} type="primary" className="btn-next" onClick={handelfifthStepRegister}>
            متابعة الاشتراك
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Plans;
