import {
  DECREMENT_DASHBOARD_DATA_COUNTER,
  FETCH_DASHBOARD_DATA,
  FETCH_DASHBOARD_DATA_ERROR,
  FETCH_DASHBOARD_DATA_SUCCESS,
} from './dashboard.type';

export const fetchDashboardDataAction = () => ({
  type: FETCH_DASHBOARD_DATA,
});

export const fetchDashboardDataSuccessAction = (data) => ({
  type: FETCH_DASHBOARD_DATA_SUCCESS,
  payload: data,
});

export const fetchDashboardDataErrorActino = (error) => ({
  type: FETCH_DASHBOARD_DATA_ERROR,
  payload: error,
});

export const decrementDashbaordCounterAction = (field) => ({
  type: DECREMENT_DASHBOARD_DATA_COUNTER,
  payload: field,
});
