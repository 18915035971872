import { Card, message } from 'antd';
import React, { useEffect, useState } from 'react';
import './style.scss';
import subscriptionIcon from '../../images/subscription.svg';
import LeadsHome from '../../images/leadsHome.svg';
import newLeads from '../../images/newleads.svg';
import { Line, Pie } from 'react-chartjs-2';
import Api from '../../Network/ApiConfig';
import { CalendarOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
// import { fetchDashboardDataSuccessAction } from '../../redux/dashboard/dashboard.action';

const { Meta } = Card;

export const Dashboard = () => {
  // const dispatch = useDispatch();
  const dashboardData = useSelector(state => state.dashboard);
  const { pieData, lineData } = dashboardData;
  const chartsData = dashboardData?.charts;
  console.log('Dashboard chartsData:', chartsData);
  // const [dashboardData, dispatch(fetchDashboardDataSuccessAction(res.data.data))] = useState({});
  const [value, setValue] = useState(1);
  // const [lineData, setLineData] = useState({});
  // const [pieData, setPieData] = useState({});
  const history = useHistory();
  const onChange = e => {
    setValue(e.target.value);
  };

  // const fetchHomeData = () => {
  //   Api.get('home').then((res) => {
  //     if (res.data.validation.length > 0) {
  //       message.error(res.data.validation[0]);
  //     }
  //     if (res?.data?.code === 105) {
  //       message.error(res.data.message);
  //     }
  //     if (res.data.code === 200) {
  //       dispatch(fetchDashboardDataSuccessAction(res.data.data))(res.data.data);

  //       const data = {
  //         labels: res.data.data?.charts.months,
  //         datasets: [
  //           {
  //             label: 'Profile Views',
  //             data: res.data.data?.charts?.profile_views,
  //             fill: true,
  //             backgroundColor: 'rgba(75,192,192,0.2)',
  //             borderColor: 'rgba(75,192,192,1)',
  //             pointBorderColor: 'rgba(0 ,122, 255, 1)',
  //             pointBackgroundColor: 'rgba(0 ,122, 255, 1)',
  //           },
  //           {
  //             label: 'Product Views',
  //             data: res.data.data?.charts?.product_views,
  //             fill: true,
  //             backgroundColor: 'rgba(0, 26, 98, 0.2)',
  //             borderColor: 'rgba(0, 26, 98, 1)',
  //             pointBorderColor: 'rgba(215 ,215 ,215,1)',
  //             pointBackgroundColor: 'rgba(215 ,215 ,215,1)',
  //           },
  //         ],
  //       };

  //       let pieLabels = Object.keys(
  //         res.data.data?.charts?.UniqueEngagementPercentage
  //       ).map((key) => {
  //         if (key.includes('RFQ')) return key.split('RFQ').join(' ') + ' RFQ';
  //         else return key.split(/(?=[A-Z])/).join(' ');
  //       });

  //       const pieData = {
  //         labels: pieLabels,
  //         datasets: [
  //           {
  //             label: '# of Votes',
  //             data: Object.values(
  //               res.data.data?.charts?.UniqueEngagementPercentage
  //             ),
  //             backgroundColor: [
  //               'rgba(0, 122, 255, 1)',
  //               'rgba(251, 136, 50, 1)',
  //               'rgba(230, 229, 230,1)',
  //               'rgba(75, 192, 192, 1)',
  //               'rgba(144, 19, 254, 1)',
  //               'rgba(19 ,226 ,254,1)',
  //             ],
  //             borderColor: [
  //               'rgba(0, 122, 255, 1)',
  //               'rgba(251, 136, 50, 1)',
  //               'rgba(230, 229, 230,1)',
  //               'rgba(75, 192, 192, 1)',
  //               'rgba(144, 19, 254, 1)',
  //               'rgba(19 ,226 ,254,1)',
  //             ],
  //             borderWidth: 0,
  //             margin: 10,
  //           },
  //         ],
  //         legend: {
  //           fullWidth: false,
  //         },
  //       };
  //       setPieData(pieData);
  //       setLineData(data);
  //     }
  //   });
  // };

  const handleRouteToComponent = route => history.push(route);

  useEffect(() => {
    // fetchHomeData();
  }, []);

  return (
    <div className="dashboard__wrapper">
      <h2>Dashboard</h2>

      <div className="dashboard__wrapper--first">
        <Card className="card-view-holder" hoverable loading={false}>
          <img src={subscriptionIcon} alt="subscriptionIcon" />
          <Meta
            title={
              <p
                onClick={() =>
                  handleRouteToComponent('/subscription/mySubscription')
                }
              >
                <span className="inter"> Current Subscription </span>{' '}
                <span>الاشتراك الحالي</span>
              </p>
            }
          />
          <p className="second__text">
            {dashboardData?.Statistics?.current_subscription?.name}
          </p>
          <div className="subscription__date">
            <p className="second__text" style={{ display: 'inline-block' }}>
              <span className="calender-icon">
                <CalendarOutlined />{' '}
              </span>{' '}
              تاريخ الانتهاء |{' '}
              {dashboardData?.Statistics?.current_subscription?.endDate}
            </p>
          </div>
        </Card>
        <Card className="card-view-holder" hoverable loading={false}>
          <img src={LeadsHome} alt="New Leads" />
          <Meta
            title={
              <p onClick={() => handleRouteToComponent('/reports/userLeads')}>
                <span className="inter"> New Leads </span>{' '}
                <span>عدد العملاء الجدد</span>
              </p>
            }
          />
          <p className="second__text number">
            {dashboardData?.Statistics?.uniqueLeads}
          </p>
        </Card>
        <Card className="card-view-holder" hoverable loading={false}>
          <img src={newLeads} alt="New Leads" />
          <Meta
            title={
              <p onClick={() => handleRouteToComponent('/reports/allLeads')}>
                <span className="inter">All Leads</span>
                <span>اجمالي عدد العملاء</span>
              </p>
            }
          />
          <p className="second__text number">
            {dashboardData?.Statistics?.leads}
          </p>
        </Card>
      </div>
      <div className="dashboard__wrapper--first second-row">
        <Card className="card-view-holder" hoverable loading={false}>
          <img src="/images/viewInfo.svg" alt="subscriptionIcon" />
          <Meta
            title={
              <p onClick={() => handleRouteToComponent('/reports/info-view')}>
                <span className="inter"> View Information</span>{' '}
                <span>اظهار البيانات</span>
              </p>
            }
          />
          <p className="second__text number">
            <span>All: </span> {dashboardData?.Statistics?.viewInfo}
          </p>
        </Card>
        <Card className="card-view-holder" hoverable loading={false}>
          <img src="/images/CallHome.svg" alt="Call Back" />
          <Meta
            title={
              <p onClick={() => handleRouteToComponent('/reports/callBack')}>
                <span className="inter"> Call Back Requests</span>{' '}
                <span>طلب الاتصال</span>
              </p>
            }
          />
          <p className="second__text number">
            <span>All: </span> {dashboardData?.Statistics?.callBacks}
          </p>
        </Card>
        <Card className="card-view-holder" hoverable loading={false}>
          <img src="/images/rfq.svg" alt="New Leads" />
          <Meta
            title={
              <p onClick={() => handleRouteToComponent('/reports/product')}>
                <span className="inter">Product RFQ </span>
                <span>طلب سعر المنتج</span>
              </p>
            }
          />
          <p className="second__text number">
            <span>All: </span> {dashboardData?.Statistics?.productRFQs}
          </p>
        </Card>
        <Card className="card-view-holder" hoverable loading={false}>
          <img src="/images/Message.svg" alt="Message" />
          <Meta
            title={
              <p onClick={() => handleRouteToComponent('/messages')}>
                <span className="inter"> Messages </span> <span>الرسائل</span>
              </p>
            }
          />
          <div className="card__footer-wrapper">
            <p className="second__text number">
              <span>All: </span> {dashboardData?.Statistics?.messages?.all}
            </p>
            <p className="second__text number">
              <span>Unread: </span>{' '}
              {dashboardData?.Statistics?.messages?.unread}
            </p>

            <span>
              Sent from {dashboardData?.Statistics?.messages?.fromUsers} users
            </span>
          </div>
        </Card>
      </div>
      <div className="dashboard__wrapper--first third-row second-row">
        <Card
          className="card-view-holder"
          hoverable
          loading={false}
          onClick={() => handleRouteToComponent('/reports/generalRfq')}
        >
          <div className="card__header--wrapper">
            <img src="/images/products.svg" alt="subscriptionIcon" />
            <Meta
              title={
                <p className="inter">
                  General RFQ <span>اجمالي طلبات الاسعار</span>
                </p>
              }
            />
          </div>
          <div className="card__footer-wrapper">
            <p className="second__text number">
              <span className="inter"> General RFQ : </span>{' '}
              {dashboardData?.Statistics?.generalRFQs}
            </p>
          </div>
        </Card>
        <Card
          className="card-view-holder"
          onClick={() => handleRouteToComponent('/tenders')}
          hoverable
          loading={false}
        >
          <div className="card__header--wrapper">
            <img src="/images/tenders.svg" alt="subscriptionIcon" />
            <Meta
              title={
                <p className="inter">
                  Tenders <span>المناقصات</span>
                </p>
              }
            />
          </div>
          <div className="card__footer-wrapper">
            <p className="second__text number">
              <span className="inter"> New Tenders: </span>{' '}
              {dashboardData?.Statistics?.tenders?.myTenders}
            </p>
            <p className="second__text number">
              <span className="inter">My Tenders: </span>{' '}
              {dashboardData?.Statistics?.tenders?.openedTenders}
            </p>
          </div>
        </Card>

        <Card className="card-view-holder" hoverable loading={false}>
          <div className="card__header--wrapper">
            <img src="/images/products.svg" alt="products" />
            <Meta
              title={
                <p
                  onClick={() => handleRouteToComponent('/profile/all-product')}
                >
                  <span className="inter"> Products</span>{' '}
                  <span>عدد المنتجات</span>
                </p>
              }
            />
          </div>
          <div className="card__footer-wrapper">
            <p className="second__text number">
              <span className="inter">All: </span>{' '}
              {dashboardData?.Statistics?.products?.all}
            </p>
            <p className="second__text number">
              <span className="inter">Visible: </span>{' '}
              {dashboardData?.Statistics?.products?.visible}
            </p>
          </div>
        </Card>

        <Card className="card-view-holder" hoverable loading={false}>
          <div className="card__header--wrapper">
            <img src="/images/cats.svg" alt="New Leads" />
            <Meta
              title={
                <p
                  onClick={() =>
                    handleRouteToComponent('/profile/sub-categories')
                  }
                >
                  <span className="inter"> Sub-Categories </span>{' '}
                  <span>عدد الاقسام</span>
                </p>
              }
            />
          </div>

          <div className="card__footer-wrapper">
            <p className="second__text number">
              <span>All: </span> {dashboardData?.Statistics?.categories?.all}
            </p>
            <p className="second__text number">
              <span>Visible: </span>{' '}
              {dashboardData?.Statistics?.categories?.visible}
            </p>
          </div>
        </Card>

        <Card className="card-view-holder" hoverable loading={false}>
          <div className="card__header--wrapper">
            <img src="/images/whatsapp-outline.svg" alt="whatsapp" width={36} />
            <Meta
              title={
                <p
                // onClick={() =>
                //   handleRouteToComponent('/profile/sub-categories')
                // }
                >
                  <span className="inter"> WhatsApp clicks </span>{' '}
                  <span>عدد نقرات واتساب</span>
                </p>
              }
            />
          </div>

          <p className="second__text number">
            <span>All: </span>{' '}
            {dashboardData?.Statistics?.counters?.whatsapp_counter}
          </p>
        </Card>
      </div>

      {/* <div className='charts__wrapper'>
        <div className='cline__charts--view'>
          <p>رسم بياني للمشاهدات</p>
          <p className='inter'>
            Views Chart(Profile Views: 1048 - Product Views: 1404){' '}
          </p>
          <Line
            legend={{
              display: true,
              labels: {
                boxWidth: 50,
                usePointStyle: true,
              },
            }}
            height={100}
            data={lineData}
          />
        </div> */}
      <div className="charts__wrapper">
        <div className="cline__charts--view">
          <p>رسم بياني لمشاهدات المنتجات</p>
          <p className="inter">
            {`الاجمالي: ${chartsData?.total_product_view}`}
          </p>

          <Line
            height={110}
            data={{
              labels: chartsData?.months,
              datasets: [
                {
                  label: `مشاهدات المنتجات`,
                  data: chartsData?.product_views,
                  fill: false,
                  borderColor: '#1890ff',
                  tension: 0.1,
                  hideInLegendAndTooltip: true,
                },
              ],
            }}
          />
        </div>
        <div className="cline__charts--view">
          <p>رسم بياني لمشاهدات الملف الشخصي</p>
          <p className="inter">
            {`الاجمالي: ${chartsData?.total_profile_views}`}
          </p>
          <Line
            height={110}
            data={{
              labels: chartsData?.months,
              datasets: [
                {
                  label: `مشاهدات الملف الشخصي`,
                  data: chartsData?.profile_views,
                  fill: false,
                  borderColor: '#FABF31',
                  tension: 0.1,
                },
              ],
            }}
          />
        </div>

        {/* <div className="cline__charts--view pie">
          <p>نسبة تفاعل العملاء</p>
          <p className="inter">Unique Engagement Percentage </p>
          <Pie
            legend={{
              display: true,
              labels: {
                boxWidth: 50,
                usePointStyle: true,
              },
            }}
            height={100}
            data={pieData}
          />
        </div> */}
      </div>
    </div>
  );
};
