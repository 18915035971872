import { useEffect, useState } from 'react';
import Api from '../../Network/ApiConfig';
import moment from 'moment';
import './style.scss';
import { Button, Input, message } from 'antd';

export const Message = () => {
  const [messageList, setMessageList] = useState([]);
  const [userMessageList, setUserMessageList] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [messageValue, setMessageValue] = useState('');
  const supplierInfo = JSON.parse(localStorage.getItem('userInfo'));

  const fetchChats = () => {
    let header = {
      'Content-Type': 'multipart/form-data;',
    };
    let body = new FormData();
    body.append('userId', supplierInfo.id);

    Api.post('reports/user-chats', body, header).then((res) => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        setMessageList(res.data.data.user_chats);
        if (res.data.data.user_chats?.length > 0) {
          fetchMessages(
            res.data.data.user_chats[0]?.user.id,
            res.data.data.user_chats
          );
        }
      }
    });
  };

  const fetchMessages = (id, list) => {
    let header = {
      'Content-Type': 'multipart/form-data;',
    };
    let body = new FormData();
    body.append('userId', id);

    const activeChatMsg = list
      ? list.find((i) => i.user.id === id)
      : messageList.find((i) => i.user.id === id);
    setActiveChat(activeChatMsg);
    Api.post('reports/user-chat', body, header).then((res) => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        setUserMessageList(res.data.data.user_chat.reverse());
      }
    });
  };

  useEffect(() => {
    fetchChats();
  }, []);

  useEffect(() => {
    setActiveChat(activeChat);
  }, [activeChat]);

  const getFirstLetters = (str) => {
    const firstLetters = str
      ?.split(' ')
      .map((word) => word[0])
      .join('');

    return firstLetters;
  };

  const handleSendMessage = () => {
    let body = {
      userId: activeChat?.user?.id,
      message: messageValue,
    };
    Api.post('reports/sendMessage', body).then((res) => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        setMessageValue('');
        fetchMessages(activeChat?.user?.id);
      }
    });
  };

  return (
    <div className='message__wrapper'>
      <div className='message__wrapper--users'>
        {messageList.map((message) => (
          <div
            key={message.user.id}
            onClick={() => fetchMessages(message.user.id)}
            className={`message__name ${
              message.user.id === activeChat?.user?.id ? 'active' : ''
            }
                     ${message.unseenMessagesCheck !== 1 ? 'unseen' : ''}`}
          >
            <span className='chat__avatar'>
              {getFirstLetters(message.user.company_name)}
            </span>
            <div>
              <p className='name__date'>
                {message.user.companyName}
                <span> {message.date} </span>
              </p>
              <p>{message.lastMessageToShow}</p>
            </div>
          </div>
        ))}
      </div>
      <div className='message__chat--details'>
        {activeChat && (
          <div className='chat__header'>
            <span className='chat__avatar'>
              {getFirstLetters(activeChat?.user?.company_name)}
            </span>
            <div>
              <p className='name__date'>
                {activeChat?.user?.user_name}
                <span> {activeChat?.date} </span>
              </p>
              <p>
                {activeChat?.user?.user_name} .{' '}
                <span> {activeChat?.user?.user_phone} </span>
              </p>
            </div>
          </div>
        )}
        <div className='chat__body'>
          {userMessageList.map((chat) => (
            <div
              className={`chat--message  ${
                chat.from === 'User' ? 'from' : 'to'
              }`}
            >
              <p>{chat.body}</p>
              <span>{chat.date}</span>
            </div>
          ))}
        </div>
        <div className='message__send'>
          <Input
            value={messageValue}
            onChange={(e) => setMessageValue(e.target.value)}
            placeholder='اكتب رسالتك ...'
            className='message__send--input'
          />
          <Button onClick={handleSendMessage}>
            <img src='/images/messageArrow.svg' alt='Send' />
          </Button>
        </div>
      </div>
    </div>
  );
};
