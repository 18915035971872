import { Col, Row, Tag } from 'antd';
import moment from 'moment';
import React from 'react';
import { useLocation } from 'react-router-dom';
import './style.scss';

export const OrderDetails = () => {
  const location = useLocation();
  const orderData = location.state;
  console.log({ orderData });

  const currencyFormat = value => {
    if (typeof value === 'string') {
      let initialValue = value.replace(/[^0-9,.]+/g, '').split('.');
      let valuesWithComma = initialValue[0]
        .split(',')
        .join('')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      initialValue.splice(0, 1, valuesWithComma);
      return `${initialValue.join('.')}` || value;
    } else {
      return (
        `${value?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`.split('.')[0] || value
      );
    }
  };

  return (
    <div className="content__wrapper">
      <div className="order__details">
        <div className="content__header">
          <p>Order #{orderData?.id}</p>
        </div>

        <div className="order__details--info">
          <div className="order__details--status">
            <p style={{ alignItems: 'center' }}>
              <img src="/images/dateIcon.png" alt="Date" />
              <span>Date placed - تاريخ الطلب </span>
              <p>{orderData?.date_placed}</p>
            </p>
            <p>
              Order Status{' '}
              <Tag
                className={`order__status--tag ${orderData?.order_status.toLowerCase()}`}
              >
                {' '}
                {orderData?.order_status}{' '}
              </Tag>
            </p>
          </div>
          <div className="order__info">
            <div className="order__info--header">
              <p>Order Items</p>
              <p>عناصر الطلب</p>
            </div>
            <div className="order__products">
              {orderData?.items.map((item, idx) => (
                <div key={idx} className="order__products-info">
                  <div className="order__products--details">
                    <img src={item?.product?.mainImage} alt="product" />
                    <div className="order__title">
                      <p>{item.product?.name}</p>
                      <p>عدد: {item.quantity}</p>
                      {item?.product?.promotion ? (
                        <>
                          <p
                            style={{
                              textDecoration: 'line-through',
                              color: '#828385',
                            }}
                          >
                            {' '}
                            {item?.product?.price} جنيه
                          </p>
                          <p>
                            السعر: {item?.product?.promotion?.promotion_price}{' '}
                            جنيه
                          </p>
                        </>
                      ) : (
                        <p>السعر: {item?.product?.price} جنيه</p>
                      )}
                    </div>
                  </div>
                  <p className="price">
                    اجمالي: {orderData.total_amount} جنيه{' '}
                  </p>
                </div>
              ))}
            </div>
            <Row gutter={50} className="order__info--address">
              <Col xs={24} md={12} order={1}>
                <p>
                  <span>العنوان</span>
                  {orderData?.address}
                </p>
              </Col>
              <Col xs={24} md={12} order={5}>
                <p>
                  <span>طريقة الدفع</span>
                  <p className="bold">{orderData?.payment_type}</p>
                </p>
              </Col>
              <Col xs={24} md={12} order={3}>
                <p>
                  <span>رقم الموبايل</span>
                  <p className="bold">{orderData?.user_phone}</p>
                </p>
              </Col>
              <Col xs={24} md={12} order={4}>
                <p>
                  <span>اسم المستخدم</span>
                  <p className="bold">{orderData?.user_name}</p>
                </p>
              </Col>
              <Col xs={24} md={12} order={2}>
                <p>
                  <span>اسم الشركة</span>
                  <p className="bold">{orderData?.user_company_name}</p>
                </p>
              </Col>
            </Row>

            <div className="order__info--footer">
              <p>
                <p
                  className="rtl-direction"
                  style={{ width: 'unset', margin: 0 }}
                >
                  {' '}
                  {currencyFormat(orderData?.subtotal)} جنيه
                </p>
                <span>المجموع</span>
              </p>
              <p>
                <p
                  className="rtl-direction"
                  style={{ width: 'unset', margin: 0 }}
                >
                  {' '}
                  {/* {currencyFormat(orderData?.delivery_fees)} جنيه */}
                  تحدد مع العميل
                </p>
                <span>مصاريف الشحن</span>
              </p>
              <p className="bold">
                <p
                  className="rtl-direction bold"
                  style={{ width: 'unset', margin: 0 }}
                >
                  {' '}
                  {currencyFormat(orderData?.total_amount)} جنيه
                </p>
                <span>الاجمالي</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
