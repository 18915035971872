import React, { Component } from "react";
import { Form, Input, Button, message } from "antd";
import { userForgetPassword } from "../../Network/authentication.network";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import './style.scss';
import Logo from '../../images/login-logo.png'

class ForgetPassword extends Component {
  constructor() {
    super();
    this.state = {
      btnLoading: false,
      isLoginValid: false,
    };
  }

  componentDidMount() {
  }


  onFinish = (values) => {

    let body = new FormData();
    body.append("email", values.username);

    this.setState({ btnLoading: true })

    userForgetPassword(
      body,
      (res) => {
        this.setState({ btnLoading: false })
        if (res.data.validation.length > 0) {
          this.setState({ isLoginValid: true })
        }
        if (res.data.code === 200) {
          message.success(res?.data?.message);
          setTimeout(() => { 
            this.props.history.push("/login");
          }, 500)
        }
      },
      (error) => {
        console.log(error);
        message.error("البريد الإلكتروني المحدد غير صالح.");
        this.setState({ btnLoading: false })
      }
    );

  };
  render() {
    const { btnLoading } = this.state;
    return (
      <div className="login__wrapper">
        <div className="login__wrapper--center">
          <div className="login__logo">
                <img src={Logo} alt="Supplier" />
            <p>هل نسيت كلمة المرور؟</p>
            <p>أدخل بريدك الإلكتروني لتلقي رابط إعادة تعيين كلمة المرور</p>
          </div>

          <Form
            name="basic"
            className="login__form"
            onFinish={this.onFinish}
          >
            <Form.Item
              label="البريد الالكتروني"
              name="username"
              rules={[
                {
                  required: true,
                  message: "الرجاء إدخال البريد الالكتروني الخاص بك!",
                },
              ]}
            >
              <Input placeholder="Emailaddress@gmail.com" />
            </Form.Item>

            <div className="login-btn">
              <Button type="primary" htmlType="submit" loading={btnLoading}>
                ارسال
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const ForgetPasswordWithRouter = withRouter(ForgetPassword);
export default connect()(ForgetPasswordWithRouter);
