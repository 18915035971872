import React, { useEffect, useState } from 'react';
import { Button, Select, Form, message } from 'antd';
import './style.scss';
import Api from '../../../../Network/ApiConfig';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';

const { Option } = Select;

export const AssignSubCategories = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [categoryList, setCategoryList] = useState([]);
  const [currentCategoryId, setCurrentCategoryId] = useState(null);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCategoriesList = () => {
    let body = {
      pagination_number: 100,
    };
    setLoading(true);
    Api.post('categories/unassigned', body).then((res) => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        setCategoryList(res.data.data.unAssignedCategories);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchCategoriesList();
  }, []);

  useEffect(() => {
    if (currentCategoryId === null) return;
    const subcategories = categoryList.find(
      (el) => el.id === currentCategoryId
    ).childs;
    setSubCategoryList(subcategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategoryId]);

  const handleChangeData = (values) => {
    let header = {
      'Content-Type': 'multipart/form-data;',
    };

    let body = new FormData();
    // values.categoryId.map((cate, idx) => {
    //   body.append(`categoryId[${idx}]`, cate);
    // });
    body.append('subCategoryId', values.subCategoryId);

    Api.post('categories/assign', body, header).then((res) => {
      setLoading(true);
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
        setLoading(false);
      } else {
        message.success(res.data.message);
        setLoading(false);
        history.push({
          pathname: '/profile/sub-categories',
        });
      }
      setLoading(false);
    });
  };

  return (
    <div className='content__wrapper'>
      <div className='assign__cate--header'>
        <p style={{ fontWeight: 'bold' }} className='inter'>
          You have: {categoryList?.length} categories
        </p>
      </div>
      <div className='product__wrapper--add'>
        <p className='product__title--page'>Add Subcategory - اضافة قسم جديد</p>
        <Form
          form={form}
          name='personalInfo'
          className='form__wrapper form__wrapper--product'
          onFinish={handleChangeData}
          autoComplete='off'
        >
          <Form.Item
            label='اختر القسم'
            name='categoryId'
            style={{ flexBasis: '100%' }}
            rules={[{ required: true, message: 'This Field is required!' }]}
          >
            {/* <Select mode='multiple' placeholder="Select Category Type" */}
            <Select
              loading={loading}
              placeholder='Select Category Type'
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              onChange={(value) => {
                setCurrentCategoryId(value);
              }}
            >
              {categoryList?.map((group, idx) => (
                <Option key={idx} title={group.name} value={group.id}>
                  {group.name_en} - {group.name_ar}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label='اختر المتنج'
            name='subCategoryId'
            style={{ flexBasis: '100%' }}
            rules={[{ required: true, message: 'This Field is required!' }]}
          >
            {/* <Select mode='multiple' placeholder="Select Category Type" */}
            <Select
              loading={loading}
              placeholder='Select Product Type'
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            >
              {subCategoryList.map((product) => (
                <Select.Option value={product?.id} key={product?.id}>
                  {product.name_en} - {product.name_ar}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexBasis: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexBasis: '100%',
              }}
            >
              <Button
                loading={loading}
                htmlType='submit'
                style={{ border: 'none', color: '#001a62' }}
              >
                {' '}
                حفظ{' '}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
