import { Layout, Menu, Dropdown, Progress, message } from 'antd';
import './style.scss';
import { DownOutlined } from '@ant-design/icons';
import { Redirect, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Api from '../../../Network/ApiConfig';
import { EyeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentSubAction, setCurrentUser } from '../../../redux/user/user.action';
const { Header } = Layout;

export const MainHeader = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const currentUser = JSON.parse(localStorage.getItem('userInfo'));
  const currentUser = useSelector(state => state.user.currentUser);
  const [profile_percentage, setprofile_percentage] = useState(null);

  useEffect(() => {
    handleprofilePercntage();
  }, []);

  const handleprofilePercntage = () => {
    Api.get('home/profilePercentage').then(res => {
      setprofile_percentage(res?.data?.data?.profile_percentage);
    });
  };

  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => history.push('/profile/PersonalInfo')}>
        Profile
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="1"
        onClick={async () => {
          try {
            const res = await Api.get('logout');
            console.log('onClick={async  res:', res);
            message.success(res.data?.message);
          } catch (error) {
            console.log('onClick={async  error:', error);
          }

          localStorage.removeItem('token');
          dispatch(setCurrentUser({}));
          dispatch(setCurrentSubAction({}));
          // window.location.reload(false);
          <Redirect to={'/login'} />;
        }}>
        Log out
      </Menu.Item>
    </Menu>
  );
  console.log(currentUser);
  return (
    <Header className="site-layout-background">
      <a
        className="show-supplier-btn"
        href={`https://elsupplier.com/shop/${currentUser?.company}/${currentUser?.id}`}
        target="_blank"
        rel="noopener noreferrer">
        <EyeOutlined />
      </a>
      <div className="header__right">
        <Progress strokeWidth={6} strokeColor={'#1cb5e0'} width={42} type="circle" percent={profile_percentage || 0} />
        <Dropdown overlay={menu} trigger={['click']}>
          <p className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            <img className="supplier_logo" src={currentUser?.logo} alt="logo" />
            {currentUser?.name} <DownOutlined />
          </p>
        </Dropdown>
      </div>
    </Header>
  );
};
