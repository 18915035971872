import useIsScreenGreater from '../hooks/useIsScreenGreater';

const { createContext } = require('react');

export const layout = createContext({});

function LayoutProvider({ children }) {
  const isBigScr = useIsScreenGreater(1200);
  const isMidScr = useIsScreenGreater(992);
  const isSmlScr = useIsScreenGreater(768);

  return (
    <layout.Provider
      value={{
        isBigScr,
        isMidScr,
        isSmlScr,
      }}
    >
      {children}
    </layout.Provider>
  );
}

export default LayoutProvider;
