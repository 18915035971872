import {
  FETCH_GROUP_PRICE,
  FETCH_GROUP_PRICE_FAIL,
  FETCH_GROUP_PRICE_SUCCESS,
} from './groupPrice.type';

export const fetchGroupPriceAction = () => ({
  type: FETCH_GROUP_PRICE,
});

export const fetchGroupPriceSuccessAction = (payload) => ({
  type: FETCH_GROUP_PRICE_SUCCESS,
  payload,
});

export const fetchGroupPirceFailAction = (error) => ({
  type: FETCH_GROUP_PRICE_FAIL,
  payload: error,
});
