import React from 'react';
import './SplashScreen.scss';
import logo from '../../images/login-logo.png';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';

const SplashScreen = () => {
  const { loading } = useSelector((state) => state.splashScreen);
  return (
    <div className={`splashScreen ${!loading ? 'fade-out' : ''}`}>
      <img className='splashScreen__img' src={logo} alt='logo' />
      <Spin size='large' />
    </div>
  );
};

export default SplashScreen;