import './antd.css';
import './style.scss';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Routes from './Routes';
import { ConfigProvider, message } from 'antd';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';
import { SideBar } from './pages/Layout/SideBar';
import { MainHeader } from './pages/Layout/Header';
import login from './pages/Login/login';
import ForgetPassword from './pages/ForgetPassword/ForgetPassword';
import LoginSupplierById from './pages/LoginSupplierById/LoginSupplierById';
import NoSubscription from './pages/NoSubscription/NoSubscription';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setCurrentSubAction } from './redux/user/user.action';
import Api from './Network/ApiConfig';
import { setSplashScreenStateAction } from './redux/splashScreen/splashScreen.action';
import { fetchDashboardDataSuccessAction } from './redux/dashboard/dashboard.action';
import Plans from './pages/Plans/Plans';
import ChoosePayment from './pages/ChoosePayment/ChoosePayment';
import RegistrationDone from './pages/RegistrationDone/RegistrationDone';
import SplashScreen from './Component/SplashScreen/SplashScreen';
import ContextProvider from './context';

const { Content } = Layout;

const App = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  const currentLang = useSelector(state => state?.user.currentLang, shallowEqual);
  const currentUser = useSelector(state => state?.user.currentUser);
  const hasSubscription = useSelector(state => state?.user.currentSub);
  const fetchHomeData = () => {
    dispatch(setSplashScreenStateAction(true));
    Api.get('home')
      .then(res => {
        dispatch(setSplashScreenStateAction(false));
        if (res.data.validation.length > 0) {
          message.error(res.data.validation[0]);
        }
        if (res?.data?.code === 105) {
          message.error(res.data.message);
        }
        if (res.data.code === 200) {
          const lineData = {
            labels: res.data.data?.charts.months,
            datasets: [
              {
                label: 'Profile Views',
                data: res.data.data?.charts?.profile_views,
                fill: true,
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
                pointBorderColor: 'rgba(0 ,122, 255, 1)',
                pointBackgroundColor: 'rgba(0 ,122, 255, 1)',
              },
              {
                label: 'Product Views',
                data: res.data.data?.charts?.product_views,
                fill: true,
                backgroundColor: 'rgba(0, 26, 98, 0.2)',
                borderColor: 'rgba(0, 26, 98, 1)',
                pointBorderColor: 'rgba(215 ,215 ,215,1)',
                pointBackgroundColor: 'rgba(215 ,215 ,215,1)',
              },
            ],
          };

          let pieLabels = Object.keys(res.data.data?.charts?.UniqueEngagementPercentage).map(key => {
            if (key.includes('RFQ')) return key.split('RFQ').join(' ') + ' RFQ';
            else return key.split(/(?=[A-Z])/).join(' ');
          });

          const pieData = {
            labels: pieLabels,
            datasets: [
              {
                label: '# of Votes',
                data: Object.values(res.data.data?.charts?.UniqueEngagementPercentage),
                backgroundColor: [
                  'rgba(0, 122, 255, 1)',
                  'rgba(251, 136, 50, 1)',
                  'rgba(230, 229, 230,1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(144, 19, 254, 1)',
                  'rgba(19 ,226 ,254,1)',
                ],
                borderColor: [
                  'rgba(0, 122, 255, 1)',
                  'rgba(251, 136, 50, 1)',
                  'rgba(230, 229, 230,1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(144, 19, 254, 1)',
                  'rgba(19 ,226 ,254,1)',
                ],
                borderWidth: 0,
                margin: 10,
              },
            ],
            legend: {
              fullWidth: false,
            },
          };
          dispatch(
            fetchDashboardDataSuccessAction({
              ...res.data.data,
              pieData,
              lineData,
            })
          );
          // setPieData(pieData);
          // setLineData(data);
        }
      })
      .catch(() => message.error('Error, Please try again later'));
  };

  useEffect(() => {
    if (currentUser && token) {
      dispatch(setSplashScreenStateAction(true));
      Api.get('subscriptions/currentSubscription')
        .then(res => {
          dispatch(setSplashScreenStateAction(false));
          if (res.data.code === 200) {
            dispatch(setCurrentSubAction(res.data.data.currentSubscription));
          }
        })
        .catch(() => {
          message.error('Error Please try again');
        });
      fetchHomeData();
    }
  }, [currentUser, dispatch, token]);

  const history = useHistory();
  return (
    <div className="app">
      <SplashScreen />
      <ContextProvider>
        <ConfigProvider direction={currentLang === 'en' ? 'ltr' : 'ltr'}>
          <Router history={history}>
            <Route path="/supplier=:id" component={LoginSupplierById} />
            <Switch>
              {token && currentUser ? (
                <Layout>
                  {/* !!IMPORTANT  Change this to (!hasSubscription) */}
                  {hasSubscription === null ? (
                    <>
                      <SideBar noMenu />
                      <Layout style={{ overflowY: 'scroll' }}>
                        <MainHeader />
                        <Route exact path="/" component={NoSubscription} />
                        <Route exact path="/plans" component={Plans} />
                        <Route exact path="/choose-payment" component={ChoosePayment} />
                        <Route exact path="/registration-done" component={RegistrationDone} />
                      </Layout>
                    </>
                  ) : (
                    <>
                      <SideBar />
                      <Layout className="site-layout">
                        <MainHeader />
                        <Content
                          className="site-layout-background"
                          style={{
                            padding: 24,
                          }}>
                          <Switch>
                            <Routes />
                          </Switch>
                        </Content>
                      </Layout>
                    </>
                  )}
                </Layout>
              ) : (
                <>
                  {history.location?.pathname?.includes('/forget-password') ? (
                    <>
                      <Route exact path="/forget-password" component={ForgetPassword} />
                      <Redirect to={'/forget-password'} />
                    </>
                  ) : (
                    <>
                      <Route exact path="/login" component={login} />
                      <Redirect to={'/login'} />
                    </>
                  )}
                </>
              )}
              {/* <Redirect to='/404' /> */}
              {/* <Route path='*' component={NotFound} /> */}
            </Switch>
          </Router>
        </ConfigProvider>
      </ContextProvider>
    </div>
  );
};

export default App;
