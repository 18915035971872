import axios from 'axios';
import { baseUrl } from './links';

// const url = 'https://portal.elsupplier.com/stage/public/api/supplier/'; // link for developement (vercel)
// const url = 'https://portal.elsupplier.com/backend/public/api/supplier/'; // link for production

export const userLogin = (body, onSucess, onFail) => {
  axios({
    method: 'post',
    url: `${baseUrl}login`,
    data: body,
    headers: { 'Content-Type': 'multipart/form-data;' },
  })
    .then(response => onSucess(response))
    .catch(err => onFail(err));
};

export const userForgetPassword = (body, onSucess, onFail) => {
  axios({
    method: 'post',
    url: `${baseUrl}forgot_password`,
    data: body,
    headers: { 'Content-Type': 'multipart/form-data;' },
  })
    .then(response => onSucess(response))
    .catch(err => onFail(err));
};
