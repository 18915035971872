import { message } from 'antd';
import axios from 'axios';
import { baseUrl } from './links';
// import { toast } from "react-toastify";

let paxios = axios.create();

export const errorCatch = () => {};

paxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      localStorage.removeItem('token');
      window.location.reload();
    }
    if (500 === error.response.status) {
      message.error('Sorry. something went wrong.');
    }
    // else {
    //     return Promise.reject(error);
    // }
  }
);

export default class Api {
  static async get(route, headers) {
    return await this.execute(route, null, 'get', headers);
  }

  static async put(route, params, headers) {
    return await this.execute(route, params, 'put', headers);
  }

  static async post(route, params, headers) {
    return await this.execute(route, params, 'post', headers);
  }

  static async delete(route, params, headers) {
    return await this.execute(route, params, 'delete', headers);
  }

  static async execute(route, params, verb, headersParam) {
    // let request = ["http://64.227.117.9/backend/public/api/supplier/" + route];
    // let request = [
    //   'https://portal.elsupplier.com/backend/public/api/supplier/' + route,
    // ];
    // let request = [
    //   'https://portal.elsupplier.com/backend/public/api/supplier/' + route,
    // ];
    let request = [baseUrl + route];
    //let request = ["https://localhost:44394/api/" + route];
    let config = {
      headers: headersParam
        ? {
            Authorization: localStorage.getItem('token'),
            ...headersParam,
          }
        : {
            Authorization: localStorage.getItem('token'),
            'Content-Type': 'application/json;',
          },
    };
    if (route === 'login') {
      config = {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      };
    }

    if (params) request.push(params);
    let result = await paxios[verb](...request, config);
    return result;
  }
}
