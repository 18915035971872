import {
  SET_CURRENT_USER,
  SET_LANG,
  SET_META_DATA,
  SET_CURRENT_CLIENT,
  UPDATE_CURRENT_USER,
  SET_USER_REQUEST,
  SET_ROLE_ID,
  SET_CITIES,
  SET_GOVERNATES,
  SET_CURRENT_SUB,
  RESET_USER,
} from './user.type';
// import { browserHistory  } from 'react-router'

export const setCurrentUser = (user) => ({
  type: SET_CURRENT_USER,
  payload: user,
});

export const setCurrentClient = (user) => ({
  type: SET_CURRENT_CLIENT,
  payload: user,
});

export const setUserRequest = (user) => ({
  type: SET_USER_REQUEST,
  payload: user,
});

export const updateCurrentUser = (data) => ({
  type: UPDATE_CURRENT_USER,
  payload: data,
});

export const setCurrentLang = (lang) => ({
  type: SET_LANG,
  payload: lang,
});

export const setMetaData = (data) => ({
  type: SET_META_DATA,
  payload: data,
});

export const setCitiesData = (data) => ({
  type: SET_CITIES,
  payload: data,
});

export const setGovernatiesData = (data) => ({
  type: SET_GOVERNATES,
  payload: data,
});

export const setRoleId = (data) => ({
  type: SET_ROLE_ID,
  payload: data,
});

export const setCurrentSubAction = (sub) => ({
  type: SET_CURRENT_SUB,
  payload: sub,
});

export const resetUserAction = () => ({
  type: RESET_USER,
})
