import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, Input, Select, Form, Upload, message } from 'antd';
import './style.scss';
import Api from '../../../../Network/ApiConfig';
import { WithContext as ReactTags } from 'react-tag-input';
// import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { useHistory, useLocation } from 'react-router-dom';
// import ImgCrop from 'antd-img-crop';
import {
  ExclamationCircleOutlined,
  FileImageOutlined,
} from '@ant-design/icons';

import TextArea from 'antd/lib/input/TextArea';
import useSearchQuery from '../../../../hooks/useSearchQuery';

const { confirm } = Modal;
const { Option } = Select;

export const AddProduct = () => {
  const { searchQueryStr } = useSearchQuery();
  const [form] = Form.useForm();
  const [groupList, setGroupList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [unitsList, setUnitsList] = useState([]);
  const [productTags, setProductTags] = useState([]);
  // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [imgList, setImgList] = useState([]);
  const [mainImage, setMainImage] = useState([]);
  const [pdfList, setPdfList] = useState([]);
  const [groupListWithPrice, setGroupListWithPrice] = useState([]);
  const [priceValue, setPriceValue] = useState([]);
  const [videoLink, setVideoLink] = useState('');
  const [ProductSpec, setProductSpec] = useState({
    firstSpec: '',
    SecondSpec: '',
  });
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const uploadBtnRef = useRef(null);
  const history = useHistory();

  const location = useLocation();
  console.log('AddProduct  location', location);

  const fetchCategoriesListSucess = res => {
    console.log('res from catogory', res);
    if (res?.data?.validation?.length > 0) {
      message.error(res.data.validation[0]);
    }
    if (res?.data?.code === 200) {
      setCategoryList(res.data.data.categories);
    }
  };

  const fetchGroupListSucess = res => {
    // Api.post('groups/get', body).then((res) => {
    if (res.data.validation.length > 0) {
      message.error(res.data.validation[0]);
    }
    if (res.data.code === 200) {
      let data = [];
      res.data.data.my_groups.forEach(item => {
        let itemData = item;
        item.group_price = 0;
        data.push(itemData);
      });
      if (!groupListWithPrice.length) setGroupListWithPrice(data);
      setGroupList(res.data.data.my_groups);
      if (location.state?.productId) {
        // Api.get(`products/show?productId=${location.state.productId}`).then(
        //   (res) => {
        //     setGroupListWithPrice(res.data.data.product?.groups_prices);
        //     let tags = res.data.data.product?.keywords?.split(',');
        //     const localTags = tags?.map((tag) => {
        //       return { text: tag, id: tag };
        //     });
        //     setProductTags(localTags);
        //   }
        // );
      }
    }
    // });
  };

  const fetchMeasurementsUnitsListSucess = res => {
    // Api.get('products/unit/measurements').then((res) => {
    if (res.data.validation.length > 0) {
      message.error(res.data.validation[0]);
    }
    if (res.data.code === 200) {
      console.log('unit from api call', res.data.data);
      setUnitsList(res.data.data.unit_measurements);
    }
    setLoading(false);
    // });
  };

  const fetchProductWithIdSuccess = productData => {
    // if (location.state?.productId) {
    // setLoading(true);
    // Api.get(`products/show?productId=${location.state.productId}`).then(
    // (res) => {
    // const productData = res?.data?.data?.product;
    form.setFieldsValue({
      categoryId: productData.allCategoris?.find(
        i => i.name_en === productData.subCategoryEnName
      )?.id,
      video: productData?.video,
      name: productData?.name,
      unitId: productData.allUnits?.find(
        i => i.name === productData?.unit_measurement
      )?.id,
      price: productData?.price,
      description: productData?.description,
    });

    // console.log(unitsList, productData?.unit_measurement);
    setPriceValue(productData?.price);

    const mainImg = {
      uid: 5,
      // uid: '-1',
      name: productData?.mainImage.split('/')[
        productData?.mainImage.split('/').length - 1
      ],
      status: 'done',
      url: productData?.mainImage,
      thumbUrl: productData?.mainImage,
      // type: 'main',
    };

    const images = productData?.product_images?.map((img, idx) => {
      return {
        uid: img.id,
        name: img?.image.split('/')[img?.image.split('/').length - 1],
        status: 'done',
        url: img?.image,
        thumbUrl: img?.image,
      };
    });

    setImgList([...images]);
    setMainImage([mainImg]);
    // console.log(mainImg, "mainImgmainImg")
    // if (productData?.description) {
    //   const descriptionValue = EditorState.createWithContent(
    //     ContentState.createFromBlockArray(
    //       convertFromHTML(`<p>${productData?.description}</p>`)
    //     )
    //   );
    //   setEditorState(descriptionValue);
    // }
    if (productData?.product_specs[0]) {
      setProductSpec({
        firstSpec: Object.keys(productData?.product_specs[0])[0],
        SecondSpec: Object.keys(productData?.product_specs[0])[0],
      });
    }
    setVideoLink(productData?.video);
    setGroupList(productData?.groups_prices);
    setGroupListWithPrice(productData.groups_prices);
    let tags = productData?.keywords?.split(',');
    console.log('tags', tags, productData?.keywords);
    const localTags = tags?.map(tag => {
      return { text: tag, id: tag };
    });
    setProductTags(localTags);
    // }
    // );
    // }
  };

  useEffect(() => {
    let body = {
      pagination_number: 100,
    };
    let arrayOfReq = [
      Api.post('categories/assigned', body),
      Api.get('products/unit/measurements'),
    ];

    if (location?.state?.productId) {
      // check if this is the edit product page, then replace groupList promise because we will get the groupList from the product req anyways;
      arrayOfReq.push(
        Api.get(`products/show?productId=${location.state.productId}`)
      );
    } else {
      arrayOfReq.push(Api.post('groups/get', body));
    }
    Promise.all(arrayOfReq)
      .then(values => {
        const categoriesRes = values[0];
        const unitsRes = values[1];
        const productDataRes = values[2];
        console.log(values);

        fetchCategoriesListSucess(categoriesRes);
        fetchMeasurementsUnitsListSucess(unitsRes);
        if (location?.state?.productId) {
          fetchProductWithIdSuccess({
            ...productDataRes?.data?.data.product,
            allCategoris: categoriesRes?.data.data.categories,
            allUnits: unitsRes?.data.data.unit_measurements,
          });
          fetchGroupListSucess(productDataRes?.data?.data?.groups_prices);
        } else {
          const groupPriceRes = values[2];
          fetchGroupListSucess(groupPriceRes);
        }
      })
      .catch(err => console.log('promise all error', err));
  }, []);
  // console.log(imgList);

  const handleChangeData = values => {
    let header = {
      'Content-Type': 'application/json;',
    };

    // if (!!!editorState.getCurrentContent().getPlainText()) {
    //   message.error('Description is Required');
    //   return false;
    // }

    setBtnLoading(true);
    let groupListData = [];

    // if (groupListWithPrice.find((i) => !!!i.group_price)) {
    //   message.error('you have to Add Group prices');
    //   setBtnLoading(false);
    //   return false;
    // }

    groupList.forEach(item => {
      groupListData.push({
        user_group_id: item.id,
        group_price: item.group_price,
      });
    });

    let tags = [];
    productTags?.forEach(tag => {
      tags.push(tag.text);
    });
    let taaags = tags.join(',');
    let body = new FormData();
    body.append('categoryId', values.categoryId);

    body.append(`keywords`, taaags);
    body.append('description', values.description);
    body.append('name', values.name);
    body.append('price', priceValue || 0);
    if (!!videoLink) body.append('video', videoLink);
    body.append('availability', 1);
    groupListWithPrice.map((item, idx) => {
      body.append(
        `user_group_id[${idx}]`,
        location.state?.productId ? item.group_id : item.id
      );
      body.append(`group_price[${idx}]`, item.group_price);
    });
    body.append(`product_spec_name[0]`, ProductSpec.firstSpec);
    body.append(`product_spec_value[0]`, ProductSpec.firstSpec);
    body.append(`product_spec_name[1]`, ProductSpec.SecondSpec);
    body.append(`product_spec_value[1]`, ProductSpec.SecondSpec);
    body.append('page_title', `${values.name} Title`);
    body.append('unitMeasurementId', values.unitId);
    if (pdfList.length) body.append('pdfFile', pdfList[0]);

    if (imgList.length) {
      // if (!location.state) {
      console.log(imgList, 'images');
      imgList.map((img, idx) => {
        body.append('images[]', img?.originFileObj);
      });
      // }
    }

    // console.log('imgList0', imgList);
    if (mainImage.length) {
      if (mainImage[0]?.originFileObj) {
        body.append('mainImage', mainImage[0]?.originFileObj);
      }
      if (location.state?.productId) {
        console.log('if img if state productId');
        body.append('productId', location.state.productId);
        Api.post('products/update', body).then(res => {
          setBtnLoading(false);
          if (res.data.validation.length > 0) {
            message.error(res.data.validation[0]);
            setBtnLoading(false);
          } else {
            message.success(res.data.message || 'successfully');
            setBtnLoading(false);
            const page = location?.state?.pageNum;
            const url = page
              ? `/profile/all-product?page=${page}`
              : `/profile/all-product`;
            history.push(url);
          }
        });
      } else {
        Api.post('products/store', body, header).then(res => {
          setBtnLoading(false);
          if (res.data.validation.length > 0) {
            message.error(res.data.validation[0]);
            setBtnLoading(false);
          } else {
            message.success(res.data.message || 'successfully');
            setBtnLoading(false);
            history.push('/profile/all-product');
          }
        });
      }
    } else {
      message.error('الرجاء اضافة صورة اساسية للمنتج');
      setBtnLoading(false);
    }
  };

  const handleDelete = i =>
    setProductTags(productTags.filter((tag, index) => index !== i));

  const handleAddition = tag => setProductTags([...productTags, tag]);

  const handleChange = async ({ fileList }) => setImgList(fileList);
  const handleChangeMainImg = async ({ fileList }) => {
    setMainImage(fileList);
  };

  const handleAddNewImages = file => {
    console.log('file', file);
    if (location.state?.productId) {
      let body = new FormData();
      body.append('productId', location.state?.productId);
      body.append('images[]', file);

      Api.post('products/add/images', body).then(res => {
        if (res.data.validation.length > 0)
          message.error(res.data.validation[0]);

        if (res.data.code === 200)
          message.success(res.data.message || 'successfully');
      });
    }
  };

  const uploadButton = (
    <p ref={uploadBtnRef} className="image__upload--btn">
      <FileImageOutlined />{' '}
    </p>
  );
  console.log(mainImage, 'mainImage');

  const handleUserGroupPrice = (value, idx) => {
    let groupListData = [...groupListWithPrice];
    groupListData[idx].group_price = value;
    setGroupListWithPrice(groupListData);
  };

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const onRemove = file => {
    if (location.state) {
      let body = new FormData();
      body.append('productImageId', +file.uid);
      confirm({
        title: `Delete This Product Image ?`,
        icon: <ExclamationCircleOutlined />,
        okText: 'Delete',
        cancelText: 'Cancel',
        onOk: () => {
          Api.post('products/delete/image', body).then(res => {
            if (res.data.validation.length > 0) {
              message.error(res.data.validation[0]);
            }
            if (res.data.code === 200) {
              message.success(res.data.message || 'successfully');
              setImgList(imgList.filter(i => i.uid !== file.uid));
            }
          });
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  };

  return (
    <div className="content__wrapper">
      <div className="product__wrapper">
        <div className="product__wrapper--header">
          <p>المنتجات: 10</p>
          <p>العروض: 20</p>
        </div>
      </div>
      <div
        className={`product__wrapper--add  ${
          location.state ? 'prodcut-edit' : ''
        }`}
      >
        <p className="product__title--page">
          {location?.state?.productId
            ? 'Edit Product - تعديل المنتج'
            : 'Add New Product - اضافة منتج جديد'}
        </p>
        <Form
          form={form}
          name="personalInfo"
          className="form__wrapper form__wrapper--product"
          onFinish={handleChangeData}
          // onFinishFailed={
          //     document
          //         .querySelector('.ant-form-item-has-error')
          //         ?.scrollIntoView(false)}
          autoComplete="off"
        >
          <Form.Item
            label="*الصورة الاساسية من المنتج"
            style={{ flexBasis: '100%' }}
            name="mainImg"
            className="mainImage-upload"
          >
            {/* <ImgCrop 
            beforeCrop={() => false} 
            quality={0.1}> */}
            <Upload
              // action={(file) => {
              //   handleAddNewImages(file);
              // }}
              accept="image/png, image/gif, image/jpeg"
              listType="picture-card"
              fileList={mainImage}
              onChange={handleChangeMainImg}
              onPreview={onPreview}
              showUploadList={{
                removeIcon: <div style={{ display: 'none' }}></div>,
              }}
              maxCount={1}
              // onRemove={(file) =>
              //   file.type === 'main' ? false : onRemove(file)
              // }
              // showUploadList={false}
              // onRemove={(file) => onRemove(file)}
              // maxCount={1}
            >
              {mainImage.length >= 2 ? null : uploadButton}
            </Upload>
            {/* </ImgCrop> */}
          </Form.Item>
          <Form.Item
            label="اضف صورة للمنتج )يمكنك اضافة اكثر من صورة ) "
            style={{ flexBasis: '100%' }}
            name="imgs"
          >
            {/* <ImgCrop 
            beforeCrop={() => false} 
            quality={0.1}> */}
            <Upload
              action={file => {
                handleAddNewImages(file);
              }}
              accept="image/png, image/gif, image/jpeg"
              listType="picture-card"
              fileList={imgList}
              onChange={handleChange}
              onPreview={onPreview}
              showUploadList={{ downloadIcon: <p>1</p> }}
              onRemove={file => (file.type === 'main' ? false : onRemove(file))}
              // showUploadList={false}
              // onRemove={(file) => onRemove(file)}
              // maxCount={1}
            >
              {imgList.length >= 8 ? null : uploadButton}
            </Upload>
            {/* </ImgCrop> */}
          </Form.Item>

          <Form.Item
            // label='اسم المنتج'
            name="name"
            rules={[{ required: true, message: 'This Field is required!' }]}
          >
            <Input placeholder="اسم المنتج" />
          </Form.Item>

          <Form.Item
            // label='وحدة بيع المنتج'
            name="unitId"
            rules={[{ required: true, message: 'This Field is required!' }]}
          >
            <Select
              getPopupContainer={triggerNode => triggerNode.parentElement}
              placeholder="وحدة بيع المنتج"
            >
              {unitsList.map((group, idx) => (
                <Option key={idx} title={group.name} value={group.id}>
                  {group.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* <Form.Item label='* سعر المنتج' name='price' className='price__item'> */}
          <Form.Item name="price">
            {/* <span className='price__label'> السعر الاساسي *</span> */}
            <Input
              placeholder="سعر المنتج"
              value={priceValue}
              onChange={e => {
                setPriceValue(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item
            // label='اختار نوع قسم المنتج'
            name="categoryId"
            rules={[{ required: true, message: 'This Field is required!' }]}
          >
            <Select
              getPopupContainer={triggerNode => triggerNode.parentElement}
              placeholder="اختار نوع قسم المنتج"
            >
              {categoryList.map((group, idx) => (
                <Option
                  key={idx}
                  title={`${group.name_en} - ${group.name_ar}`}
                  value={group.id}
                >
                  {group.name_en} - {group.name_ar}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <div className="choose__group">
            <div
              className={`choose__group--container ${
                isCollapsed ? 'collapsed' : ''
              }`}
            >
              <p>
                سعر المنتج لكل شريحة المستخدمين
                <span
                  style={{ transform: isCollapsed ? 'rotate(180deg)' : 'none' }}
                  onClick={() => setIsCollapsed(!isCollapsed)}
                >
                  {' '}
                  <img src="/images/downArrow.svg" alt="Collapse" />{' '}
                </span>
              </p>

              {groupListWithPrice.map((group, idx) => (
                <Form.Item
                  label={
                    location.state?.productId ? group.group_name : group.name
                  }
                  name={location.state?.productId ? group.group_id : group.id}
                  className="price__item"
                >
                  <Input
                    type="number"
                    value={group.group_price}
                    onChange={e => handleUserGroupPrice(e.target.value, idx)}
                  />
                  <span className="price__label">جنيه</span>
                </Form.Item>
              ))}

              <p className="choose__group__label">كلمات البحث عن المنتج</p>
              <Form.Item
                // label='كلمات البحث عن المنتج'
                style={{ flexBasis: '100%' }}
                className="choose__group__item"
              >
                <div className="product__tags">
                  <ReactTags
                    tags={productTags}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    placeholder=""
                    autofocus={false}
                  />
                </div>
              </Form.Item>

              <p className="choose__group__label">لينك فيديو عن المنتج</p>
              <Form.Item
                // label='لينك فيديو عن المنتج'
                name="video"
                className="price__item"
                style={{ flexBasis: '100%' }}
                rules={[{ message: 'This Field is required!' }]}
              >
                <span className="price__label">
                  <img src="/images/video.svg" alt="video" />
                </span>
                <Input
                  style={{ maxWidth: '300px' }}
                  value={videoLink}
                  onChange={e => setVideoLink(e.target.value)}
                  placeholder="Enter video link ... youtube, Vimeo or Vevo"
                />
              </Form.Item>

              {/* <p className='choose__group__label'>تفاصيل ملف PDF</p>
              <Form.Item
                // label='ملف تفاصيل PDF'
                rules={[{ required: true, message: 'This Field is required!' }]}
                className='rtl-label'
              >
                <Upload
                  action={(file) => setPdfList([...pdfList, file])}
                  fileList={pdfList}
                  accept='.pdf'
                >
                  <p className='upload__pdf' style={{ direction: 'ltr' }}>
                    ارفق ملف PDF
                  </p>
                </Upload>
              </Form.Item> */}

              {/* <p className='choose__group__label'>معلومات عن المنتج</p>
              <Form.Item
                // label='معلومات عن المنتج'
                name='video'
                className='price__item'
              >
                <Input
                  value={ProductSpec.firstSpec}
                  onChange={(e) =>
                    setProductSpec({
                      ...ProductSpec,
                      firstSpec: e.target.value,
                    })
                  }
                  style={{ width: '150px', margin: '0 4px' }}
                />
                <Input
                  value={ProductSpec.SecondSpec}
                  onChange={(e) =>
                    setProductSpec({
                      ...ProductSpec,
                      SecondSpec: e.target.value,
                    })
                  }
                  style={{ width: '150px', margin: '0 4px' }}
                />
              </Form.Item> */}
              <p className="choose__group__label">وصف المنتج</p>
              <Form.Item
                // label='* وصف المنتج'
                style={{ flexBasis: '100%' }}
                // rules={[{ required: true, message: 'This Field is required!' }]}
                name="description"
              >
                {/* <Editor
                editorState={editorState}
                toolbarClassName='toolbarClassName'
                wrapperClassName='wrapperClassName'
                editorClassName='editorClassName'
                onEditorStateChange={onEditorStateChange}
              /> */}
                <TextArea autoSize={{ minRows: 4, maxRows: 5 }} />
              </Form.Item>
            </div>
            {/* <Option key={idx} title={group.name} value={group.id} /> */}
          </div>

          <Form.Item
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexBasis: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexBasis: '100%',
              }}
            >
              <Button
                onClick={() =>
                  setTimeout(() => {
                    document
                      .querySelector('.ant-form-item-has-error')
                      ?.scrollIntoView(false);
                  }, 50)
                }
                loading={btnLoading}
                htmlType="submit"
                style={{ border: 'none', color: '#001a62' }}
              >
                {' '}
                حفظ{' '}
              </Button>
              <Button
                onClick={() =>
                  setTimeout(() => {
                    document
                      .querySelector('.ant-form-item-has-error')
                      ?.scrollIntoView(false);
                  }, 50)
                }
                loading={btnLoading}
                htmlType="submit"
                style={{ background: '#001a62', color: '#fff' }}
              >
                {' '}
                + منتج جديد{' '}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
