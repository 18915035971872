import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TableGrid } from '../../Component/Table';
import useLocalPagination from '../../hooks/useLocalPagination';
import useSearchQuery from '../../hooks/useSearchQuery';
import Api from '../../Network/ApiConfig';
import { decrementDashbaordCounterAction } from '../../redux/dashboard/dashboard.action';
import { DB_TENDERS_KEY } from '../../redux/dashboard/dashboard.type';
import TendersModal from './TendersModal';

const INIT_STEP = 'INIT_STEP';
const SHOW_STEP = 'SHOW_STEP';
const REJECTED_STEP = 'REJECTED_STEP';

export const Tenders = () => {
  const [tenderList, setTenderList] = useState();
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalStep, setModalStep] = useState(INIT_STEP);
  const [tendersModalVisible, setTendersModalVisible] = useState(false);
  const [currentTendersPoints, setCurrentTendersPoints] = useState([]);
  const [selectedTenderId, setSelectedTenderId] = useState(null);
  const [selectedTender, setSelectedTender] = useState([]);
  const dispatch = useDispatch();
  const { searchQueryObj } = useSearchQuery();
  const { paginationNumber } = useLocalPagination();

  const onToggleTendersModal = () => {
    setTendersModalVisible(!tendersModalVisible);
  };

  const onCloseTendersModal = () => {
    setTendersModalVisible(false);
    setModalStep(INIT_STEP);
  };

  const handleFetchTenders = useCallback(() => {
    const fd = new FormData();
    fd.append(
      'pagination_number',
      searchQueryObj?.pageSize || paginationNumber
    );
    fd.append('page', searchQueryObj?.page || 1);
    setLoading(true);
    Api.post('tenders', fd).then(res => {
      setTenderList(res.data.data);
      dispatch(decrementDashbaordCounterAction(DB_TENDERS_KEY));
      setLoading(false);
    });
    Api.get('subscriptions/currentSubscription').then(res => {
      setCurrentTendersPoints(res?.data?.data?.currentSubscription?.tenders);
    });
  }, [searchQueryObj.page, searchQueryObj.pageSize]);

  const handleFetchSingleTender = id => {
    setModalLoading(true);
    return Api.get(`tenders/show?tenderId=${id}`).then(res => {
      setModalLoading(false);
      if (res.data.code === 200) {
        setSelectedTender(res?.data?.data?.tender);
        setModalStep(SHOW_STEP);
      } else {
        setSelectedTender({ error: true });
        setModalStep(REJECTED_STEP);
      }
    });
  };

  useEffect(() => {
    handleFetchTenders();
  }, [handleFetchTenders]);

  const columns = [
    {
      title: (
        <p className="table-header-text">
          Category Name <span>اسم القسم</span>
        </p>
      ),
      dataIndex: 'sub_category',
    },
    {
      title: (
        <p className="table-header-text">
          Tender Description <span>تفاصيل المناقصة </span>
        </p>
      ),
      dataIndex: 'message',
    },
    {
      title: (
        <p className="table-header-text">
          {' '}
          Actions <span>افعال</span>
        </p>
      ),

      dataIndex: 'user_email',
      render: (_, record) => {
        return (
          <div>
            <p
              onClick={() => {
                // setProductSelected(record);
                console.log(record);
                setSelectedTenderId(record?.id);
                if (record?.unlocked === 1) {
                  setModalStep(SHOW_STEP);
                  handleFetchSingleTender(record?.id);
                }

                setTendersModalVisible(true);
              }}
              className="add-promotion"
            >
              اظهار بيانات
            </p>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <TableGrid
        title={
          <p>
            Tenders <span> المناقصات </span>
          </p>
        }
        columns={columns}
        dataSource={tenderList?.tenders ? tenderList?.tenders : []}
        loading={loading}
        pagination={tenderList?.pagination}
        // pagination={{ pageSize: 10, total: tenderList?.pagination?.total }}
        // defaultCurrent={10}
        // total={tenderList?.pagination?.total}
        // onChange={(pagination) => {
        //   let url = `tenders?page=${pagination?.current}`;
        //   handleFetchTenders(url);
        // }}
      />

      <TendersModal
        loading={modalLoading}
        onTenderModalToggle={onToggleTendersModal}
        isVisible={tendersModalVisible}
        currentTendersPoints={currentTendersPoints}
        handleFetchSingleTender={() =>
          handleFetchSingleTender(selectedTenderId)
        }
        selectedTender={selectedTender}
        step={modalStep}
        onClose={onCloseTendersModal}
      />
    </div>
  );
};
