import { Button, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { TableGrid } from '../../../Component/Table';
import Api from '../../../Network/ApiConfig';
import './style.scss'

export const ExtrasPurchase = () => {
    const [purchaseList, setPurchaseList] = useState([]);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);

    const handleQuantityChange = (val, rec) => {
        let purchaseListData = purchaseList;
        let purchItem = purchaseListData.find(i => i.id === rec.id);
        purchItem.quantity = val;
        purchItem.total = +rec.price * +val
        purchaseListData.splice(purchaseListData.findIndex(i => i.id === purchItem.id), 1, purchItem);
        setPurchaseList(purchaseListData);

        let totalQuantityData = purchaseList.reduce((a, b) => +a + (+b['quantity'] || 0), 0);
        let totalAmountData = purchaseList.reduce((a, b) => +a + (+b['total'] || 0), 0);


        setTotalQuantity(totalQuantityData)
        setTotalAmount(totalAmountData)
    }

    const columns = [
        {
            title: <p className='table-header-text'> Name <span>النوع</span> </p>,
            dataIndex: 'user_text',
            render: (_, rec) => {
                return (
                    <div>
                        <img style={{
                            marginRight: '10px',
                            display: 'inline-block',
                            width: rec.name === 'promotion' ? '60px' : '50px',
                            marginLeft: rec.name === 'promotion' ? '-6px' : '0'
                        }} src={rec.name !== 'promotion' ?
                            `/images/subscriptionPurchase/${rec.name}.svg` : `/images/subscriptionPurchase/${rec.name}.png`
                        } alt={rec.name} />
                        <span> {rec.user_text} </span>
                    </div>
                )
            }
        },
        {
            title: <p className='table-header-text'> Price  <span>السعر</span> </p>,
            dataIndex: 'price',
            render: (_, rec) => {
                return <p style={{ color: '#001a62', fontWeight: 'bold' }}>{rec?.price?.toFixed(2)} </p>
            }
        },
        {
            title: <p className='table-header-text'> Quantity <span>الكمية</span> </p>,
            dataIndex: 'rank',
            editable: true,
            render: (_, rec) => {
                if(rec?.name === "googleBudget" || rec?.name === "facebookBudget"){
                    return null 
                }else{
                    return <Input type='number' value={rec.quantity} onChange={(e) => { handleQuantityChange(e.target.value, rec) }} />
                }
            }
        },
        {
            title: <p className='table-header-text'> Total <span>الاجمالي</span> </p>,
            dataIndex: 'total',
            render: (_, rec) => {
                return <p style={{ color: '#001a62', fontWeight: 'bold' }}> {rec.total ? rec.total?.toFixed(2) : '0.00'} </p>
            }
        },

    ];

    const handleFetchMySubscription = () => {
        Api.get('subscriptions/purchases').then(res => {
            setPurchaseList(res.data.data.purchases)
        })
    }

    const handleSubmitPurchase = () => {
        let body = {

        };

        purchaseList.map(item => {
            if (item.quantity)
                return body[`${item.name}`] = item.quantity
        });

        body.paymentTypeId = 2
        Api.post('subscriptions/purchase/request', body).then(res => {
            if (res.data.validation)
                message.error(res.data.validation[0]);
            else message.success(res.data.message);
        })
    }

    useEffect(() => handleFetchMySubscription(), []);

    return (
        <div className='purchase__wrapper'>

            <TableGrid title={<p> Purchase <span> باقات اضافية </span></p>}
                columns={columns}
                dataSource={purchaseList}
                rowKey={record => record.id}
            />

            <div className='total__amount'>
                <p>Total</p>
                <div>
                    <p className='ltr-direction'> <span className='rtl-direction'>: Quantity - الكمية </span> {totalQuantity?.toFixed(2)} </p>
                    <p> <span className='rtl-direction'>: Total - الاجمالي </span> {totalAmount?.toFixed(2)} </p>
                </div>
            </div>

            <div style={{ textAlign: 'right', padding: '20px 40px' }}>
                <Button onClick={handleSubmitPurchase} className='default'> Submit </Button>
            </div>

        </div>
    );
};
