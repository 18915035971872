export const FETCH_DASHBOARD_DATA = 'FETCH_DASHBOARD_DATA';
export const FETCH_DASHBOARD_DATA_SUCCESS = 'FETCH_DASHBOARD_DATA_SUCCESS';
export const FETCH_DASHBOARD_DATA_ERROR = 'FETCH_DASHBOARD_DATA_ERROR';
export const DECREMENT_DASHBOARD_DATA_COUNTER =
  'DECREMENT_DASHBOARD_DATA_COUNTER';

// CONSTANTS
export const DB_ALL_KEY = 'all';
export const DB_VIEWINFO_KEY = 'viewInfo';
export const DB_CALLBACK_KEY = 'callBack';
export const DB_PRODUCTRFQ_KEY = 'productRfq';
export const DB_GENERALRFQ_KEY = 'generalRfq';
export const DB_NEWLEADS_KEY = 'newLeadsCount';
export const DB_ORDERS_KEY = 'orderCount';
export const DB_TENDERS_KEY = 'tenderCount';
