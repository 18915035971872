import { Button } from 'antd';
import React from 'react';
import './Package.scss';

const Package = ({
  selectPackge,
  setSelectPackge,
  name,
  price,
  tenders,
  customers,
  sections,
  googleBudget,
  facebookBudget,
  durationInMonth,
  emails,
  promotions,
  products,
  online_shop,
  sales_commission,
  multiple_pricing,
  id,
}) => {
  return (
    <div
      className={`packge-component ${selectPackge === id ? 'selected' : ''}`}
    >
      <div className='packge-header'>
        <h2>{name}</h2>
        <p>
          <span>{price}</span> جنية / السنة
        </p>
      </div>
      <ul className='packge-content-list'>
        <li>
          <span className='head'>متجر الكتروني</span>
          <span className='desc'>{online_shop}</span>
        </li>
        <li>
          <span className='head'>تسعير متعدد</span>
          <span className='desc'>{multiple_pricing}</span>
        </li>
        <li>
          <span className='head'>عدد العملاء</span>
          <span className='desc'>{customers}</span>
        </li>
        <li>
          <span className='head'>الترتيب</span>
          <span className='desc'>{tenders}</span>
        </li>
        <li>
          <span className='head'>عموله المبيعات</span>
          <span className='desc'>{sales_commission}</span>
        </li>
        <li>
          <span className='head'>عدد المنتجات</span>
          <span className='desc'>{products}</span>
        </li>
        {sections && (
          <li>
            <span className='head'>عدد الاقسام</span>
            <span className='desc'>{sections}</span>
          </li>
        )}
        {tenders && (
          <li>
            <span className='head'>نقاط اظهار المناقصات</span>
            <span className='desc'>{tenders}</span>
          </li>
        )}
        {googleBudget && (
          <li>
            <span className='head'>Google Budget</span>
            <span className='desc'>{googleBudget}</span>
          </li>
        )}
        {googleBudget && (
          <li>
            <span className='head'>Facebook Budget</span>
            <span className='desc'>{facebookBudget}</span>
          </li>
        )}
        {durationInMonth && (
          <li>
            <span className='head'>نقاط العروض</span>
            <span className='desc'>{promotions}</span>
          </li>
        )}
        {durationInMonth && (
          <li>
            <span className='head'>المدة - (Month)</span>
            <span className='desc'>{durationInMonth}</span>
          </li>
        )}
        {emails && (
          <li>
            <span className='head'>بريد</span>
            <span className='desc'>{emails}</span>
          </li>
        )}
      </ul>
      <Button
        type='ghost'
        className='btn-select'
        onClick={() => setSelectPackge(id)}
      >
        اختيار الباقه
      </Button>
    </div>
  );
};

export default Package;
