import { REQUEST_DETAILS } from "./order.type";

const INITIAL_STATE = {
  RequestDetails:{}
};
const orderReducer = ( state = INITIAL_STATE,action) => {
  switch (action.type) {
    case REQUEST_DETAILS:
      return {
        RequestDetails: {...action.payload}
      };
    default:
      return state;
  }
};

export default orderReducer;
