import { Button, Input, message } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import React, { useCallback, useState } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TableGrid } from '../../../Component/Table';
import useSearchQuery from '../../../hooks/useSearchQuery';
import Api from '../../../Network/ApiConfig';
import './style.scss';

const BASE_PRICE_KEY = 'BASE_PRICE_KEY'; // used to check if the price input that has changed is for the base price not for a group pice

export const PriceList = () => {
  // State
  const [priceList, setPriceList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [changedPriceListIds, setChangedPricelistIds] = useState({}); // track the ids of the change product and it's group price fields
  const [loading, setLoading] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  const [customColumns, setCustomColumns] = useState([]);
  const { searchQueryStr } = useSearchQuery();

  // Redux
  const groupPriceList = useSelector(state => state?.groupPrice?.list);

  // fetching handlers
  const fetchPriceList = useCallback(() => {
    setLoading(true);
    Api.get(`products/priceList${searchQueryStr}`)
      .then(res => {
        if (res.data.code === 200) {
          setPagination(res.data.data.pagination);
          setPriceList(res.data.data.List);
          setLoading(false);
        }
      })
      .catch(e => {
        message.error('Error! Please try again later');
        setLoading(false);
      });
  }, [searchQueryStr]);

  // updating handlers
  const updatePriceListHandler = () => {
    let header = {
      'Content-Type': 'multipart/form-data;',
    };
    const arrOfReqs = [];
    for (let item in changedPriceListIds) {
      // loop over the object of the changed product price ex: {productId: {fielId: value}, ...rest}
      for (let subItem in changedPriceListIds[item]) {
        if (
          changedPriceListIds[item][subItem] === '' ||
          changedPriceListIds[item] === ''
        ) {
          // if there's an empty field return with an error message
          message.error('Error! Empty Field');
          return;
        }
        const body = new FormData();
        body.append('productId', item);
        body.append(
          'base_price',
          priceList.find(el => el.id === +item).base_price
        );
        if (subItem !== BASE_PRICE_KEY) {
          body.append('group_id[0]', subItem);
        }
        body.append('group_price[0]', changedPriceListIds[item][subItem]);
        arrOfReqs.push(Api.post('products/updatePriceList', body, header));
      }
    }
    setLoading(true);
    Promise.allSettled(arrOfReqs).then(values => {
      setLoading(false);
      setChangedPricelistIds({});
      /* It's a forEach loop that loops over the array of promises that are returned from the
     Promise.allSettled() function. */
      values.forEach(
        val =>
          val.status === 'rejected' &&
          message.error('error while updating the prices! please try again')
      );
      message.success('Prices updates successfully');
      console.log('values', values);
    });
  };

  // onChange handlers
  const onInputChange = (record, fieldId, value) => {
    /* It's converting the value to a number. */
    const numValue = +value;
    console.log('record', record.id);

    const newPriceList = [...priceList];
    const changedProductPrice = newPriceList.findIndex(
      el => el.id === record.id
    );

    /* It's checking if the fieldId is equal to the BASE_PRICE_KEY constant. If it is, it's updating
    the base_price property of the product. If it's not, it's updating the group_price property of
    the product. */
    if (fieldId === BASE_PRICE_KEY) {
      newPriceList[changedProductPrice] = {
        ...newPriceList[changedProductPrice],
        base_price: numValue,
      };
    } else {
      const changedInputInGroup = newPriceList[
        changedProductPrice
      ].groups.findIndex(el => el.group_id === fieldId);
      newPriceList[changedProductPrice].groups[changedInputInGroup] = {
        ...newPriceList[changedProductPrice].groups[changedInputInGroup],
        group_price: numValue,
      };
    }

    /* It's updating the state of the changedPriceListIds. */
    setChangedPricelistIds(prevState => {
      const newState = { ...prevState };
      newState[record.id] = { ...newState[record.id], [fieldId]: value };
      return newState;
    });
    setPriceList(newPriceList);
  };

  const filterPriceListHandler = value => {
    if (!!value) {
      let salesFiltered = priceList?.filter(el =>
        el.name.toLowerCase().includes(value.toLowerCase())
      );
      setPriceList(salesFiltered);
    } else {
      fetchPriceList();
    }
  };
  // table columns
  const columns = useMemo(() => {
    return [
      {
        title: (
          <p className="table-header-text">
            Name <span> اسم المنتج </span>
          </p>
        ),
        render: (_, record) => <p>{record?.name}</p>,
      },
      {
        title: (
          <p className="table-header-text">
            Product Image <span>الصورة</span>
          </p>
        ),
        render: (_, record) => (
          <div className="ant-table-cell">
            <img src={record?.image} alt={priceList?.name} />
          </div>
        ),
      },
      {
        title: (
          <p className="table-header-text">
            {' '}
            Base Price <span>السعر الاساسي</span>
          </p>
        ),
        render: (_, record) => (
          <Input
            type="number"
            className="table-header-text"
            defaultValue={record?.base_price || 0}
            onChange={e =>
              onInputChange(record, BASE_PRICE_KEY, e.target.value)
            }
            key={record?.id}
            min={0}
          />
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceList]);

  // useEffect
  useEffect(() => {
    fetchPriceList();
  }, [fetchPriceList]);

  useEffect(() => {
    setCustomColumns([
      ...columns,

      ...groupPriceList?.map(gp => ({
        title: <p className="table-header-text">{gp?.name}</p>,
        render: (_, record) => {
          const foundItem = record?.groups?.find(el => el.group_id === gp.id);
          // console.log('id from price', record);
          return (
            <Input
              type="number"
              key={record?.id}
              onChange={e =>
                onInputChange(record, foundItem.group_id, e.target.value)
              }
              defaultValue={foundItem.group_price || 0}
              min={0}
            />
          );
        },
      })),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupPriceList, columns]);

  // JSX
  return (
    <div>
      <TableGrid
        loading={loading}
        title={
          <div className="table__filter">
            <p className="bold">
              User groups
              <span className="normal-font" style={{ marginLeft: '10px' }}>
                قائمة الاسعار
              </span>
            </p>
            <div className="search__list--input">
              <Input
                placeholder="Search For Name"
                onChange={e => filterPriceListHandler(e.target.value)}
              />
            </div>
          </div>
        }
        columns={customColumns}
        dataSource={priceList}
        pagination={pagination}
        // pageSize={10}
        // defaultCurrent={10}
        // total={pagination?.total}
        // paginationProps={{
        //   current: currentPage,
        // }}
        // onChange={(pagination) => {
        //   if (Object.keys(changedPriceListIds) > 0) {
        //     confirm({
        //       title: `Undo changes?`,
        //       okText: 'Yes',
        //       cancelText: 'No',
        //       onCancel: () => {
        //         return;
        //       },
        //       onOk: () => {
        //         setCurrentPage(pagination?.current);
        //         setChangedPricelistIds([]);
        //       },
        //     });
        //   } else {
        //     setCurrentPage(pagination?.current);
        //     setChangedPricelistIds([]);
        //   }
        // }}
      >
        <Button
          disabled={Object.keys(changedPriceListIds).length === 0 || loading}
          type="primary"
          style={{ marginLeft: 'auto', display: 'block' }}
          onClick={updatePriceListHandler}
        >
          حفظ التعديلات
        </Button>
      </TableGrid>
    </div>
  );
};
