import React from 'react';
import { Button, Modal, Spin } from 'antd';
import './Tenders.scss';

const INIT_STEP = 'INIT_STEP';
const SHOW_STEP = 'SHOW_STEP';
const REJECTED_STEP = 'REJECTED_STEP';

const TendersModal = ({
  isVisible,
  loading,
  currentTendersPoints,
  handleFetchSingleTender,
  selectedTender,
  step,
  onClose,
}) => {
  let content = null;

  switch (step) {
    case INIT_STEP: {
      content = (
        <>
          <p className='text-sm'>
            لديك{' '}
            <span className='tenders-modal-points-count'>
              {currentTendersPoints}
            </span>{' '}
            نقطة في حسابك يمكنك اظهار بيانات المستخدم
          </p>
          <p className='text-md'>تأكيد اظهار البيانات وخصم النقاط ؟</p>
          <div className='tenders-modal-buttons'>
            <Button
              loading={loading}
              className='primary'
              onClick={() => {
                handleFetchSingleTender();
              }}
            >
              اظهار
            </Button>
            <Button onClick={() => onClose(false)} className='btn-secondary'>
              الغاء
            </Button>
          </div>
        </>
      );
      break;
    }

    case SHOW_STEP: {
      content = (
        <>
          <p className='tenders-modal-info text-sm bold'>
            اسم المستخدم : <span>{selectedTender?.user_name || 'لا يوجد'}</span>
          </p>
          <p className='tenders-modal-info text-sm bold'>
            رقم الهاتف : <span>{selectedTender?.phone || 'لا يوجد'}</span>
          </p>
          <p className='tenders-modal-info text-sm bold'>
            البريد الالكتروني :{' '}
            <span>{selectedTender?.email || 'لا يوجد'}</span>
          </p>
          <Button
            type='secondary'
            className='tenders-modal-button btn-secondary'
            onClick={() => onClose()}
          >
            الغاء
          </Button>
        </>
      );
      break;
    }

    case REJECTED_STEP: {
      content = (
        <>
          <p className='text-md'>
            عذرا ، لا يمكنك اظهار بيانات المستخدم لعدم امتلاكك نقاط كافية
          </p>
          <Button
            type='secondary'
            className='tenders-modal-button btn-secondary'
            onClick={() => onClose()}
          >
            الغاء
          </Button>
        </>
      );
      break;
    }
    default:
      return;
  }

  return (
    <Modal
      footer={null}
      destroyOnClose
      visible={isVisible}
      onCancel={() => onClose()}
    >
      <div className='tenders-modal'>
        <h3>اظهار بيانات المستخدم</h3>

        {content}
      </div>
      {loading && <Spin className='tenders-modal-spinner' />}
    </Modal>
  );
};

export default TendersModal;
