import { useContext } from 'react';
import { layout } from '../context/layout';

function useLayout() {
  const { isBigScr, isMidScr, isSmlScr } = useContext(layout);
  return {
    isBigScr,
    isMidScr,
    isSmlScr,
  };
}

export default useLayout;
