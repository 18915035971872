import { Button, message, Radio, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { ImRadioChecked2, ImRadioUnchecked } from 'react-icons/im';
import { useHistory, useLocation } from 'react-router-dom';
import online_img from '../../images/vuesax_linear_card-tick.svg';
import kash_img from '../../images/vuesax_linear_empty-wallet.svg';
import sheak_img from '../../images/vuesax_linear_receipt-edit.svg';
import Api, { websiteApiUrl } from '../../Network/ApiConfig';
import axios from 'axios';
import './ChoosePayment.scss';
import { useSelector } from 'react-redux';
import { baseUrl } from '../../Network/links';

const ChoosePayment = () => {
  const [selectedPayment, setSelectedPayment] = useState(null);
  const supplierId = useSelector(state => state?.user?.currentUser?.id);
  const [paymentsList, setPaymentsList] = useState([]);
  const location = useLocation();
  const [loading, setloading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    fetchUsersPayments();
  }, []);

  const fetchUsersPayments = () => {
    axios.get(`${baseUrl}payment/types`).then(res => {
      if (res.data.code === 200) {
        setPaymentsList(res?.data?.data?.types || []);
      }
    });
  };

  const handelSubscrib = () => {
    const subscriptionId = location?.state?.data?.subscriptionId;
    console.log(supplierId);
    console.log(subscriptionId);

    setloading(true);
    let body = new FormData();
    body.append('supplierId', supplierId);
    body.append('paymentTypeId', selectedPayment);
    body.append('subscriptionId', subscriptionId);
    Api.post('fifthStepRegister', body)
      .then(res => {
        setloading(false);
        if (res?.data?.validation?.length > 0) {
          res?.data?.validation?.forEach(err => message?.error(err));
        } else {
          if (res.data.code === 200) {
            message.success(res.data?.data?.message);
            // window.open("http://64.227.117.9/","_self")
            history.push({
              pathname: `/registration-done`,
              state: { data: res?.data?.data, isCommission: false },
            });
          }
        }
      })
      .catch(() => {
        setloading(false);
        message.error('Error, Please try again!');
      });
  };

  return (
    <div className="choose-payment-hero">
      <div className="container">
        <div className="choose-payment-holder">
          <div className="choose-payment-content">
            <h2 className="title">اختر طريقة الدفع</h2>
            <div className="payment-methods-holder">
              <h2 className="payment-methods-title">
                اختر طريقة الدفع الانسب للاشتراك
              </h2>
              <Radio.Group
                defaultValue=""
                buttonStyle="solid"
                size="large"
                onChange={e => setSelectedPayment(e.target.value)}
              >
                {loading ? (
                  <Spin size="large" style={{ margin: '1rem auto' }} />
                ) : (
                  paymentsList?.map(
                    ({ id, name, value, label, icon, extra }) => (
                      <div className="item-holder">
                        <div className="item-content">
                          <Radio.Button
                            key={id}
                            value={id}
                            className={id === selectedPayment ? 'checked' : ''}
                          >
                            {id === selectedPayment ? (
                              <ImRadioChecked2 />
                            ) : (
                              <ImRadioUnchecked />
                            )}

                            {name === 'Online' && (
                              <img src={online_img} alt={name} />
                            )}
                            {name === 'Cash' && (
                              <img src={kash_img} alt={name} />
                            )}
                            {name === 'Cheque' && (
                              <img src={sheak_img} alt={name} />
                            )}
                            {name === 'Contact Us' && (
                              <img src={kash_img} alt={name} />
                            )}
                            {name === 'PT10' && (
                              <img src={kash_img} alt={name} />
                            )}

                            {name}
                          </Radio.Button>
                        </div>
                        {name === 'Cheque' && (
                          <p className="item-extra">
                            *سيتم التواصل معك لاحقا لاتمام عملية الدفع
                          </p>
                        )}
                        {name === 'Cash' && (
                          <p className="item-extra">
                            *سيتم التواصل معك لاحقا لاتمام عملية الدفع
                          </p>
                        )}
                        {/* {extra && <p className="item-extra">{extra}</p>} */}
                      </div>
                    )
                  )
                )}
              </Radio.Group>
            </div>
            <Button
              loading={loading}
              disabled={!!!selectedPayment}
              type="primary"
              className="btn-next"
              onClick={handelSubscrib}
            >
              متابعة الاشتراك
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChoosePayment;
