import React, { useCallback, useEffect, useState } from 'react';
import { TableGrid } from '../../../Component/Table';
import Api from '../../../Network/ApiConfig';
import Image from '../../../images/download.jpeg';
// import './style.scss'
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { decrementDashbaordCounterAction } from '../../../redux/dashboard/dashboard.action';
import { DB_PRODUCTRFQ_KEY } from '../../../redux/dashboard/dashboard.type';
import useLocalPagination from '../../../hooks/useLocalPagination';
import useSearchQuery from '../../../hooks/useSearchQuery';

const columns = [
  {
    title: <p className="table-header-text"> # </p>,
    dataIndex: 'id',
  },
  {
    title: (
      <p className="table-header-text">
        {' '}
        Company Name <span> اسم الشركة </span>
      </p>
    ),
    dataIndex: 'company_name',
  },
  {
    title: (
      <p className="table-header-text">
        {' '}
        Name <span>الاسم</span>{' '}
      </p>
    ),
    dataIndex: 'user_name',
  },
  {
    title: (
      <p className="table-header-text">
        {' '}
        Phone <span> الموبايل </span>
      </p>
    ),
    dataIndex: 'user_phone',
    render: (_, record) => (
      <a href={`tel:+${record?.user_phone}`}>{record?.user_phone}</a>
    ),
  },
  {
    title: (
      <p className="table-header-text">
        {' '}
        Email <span> البريد الالكترون </span>
      </p>
    ),
    dataIndex: 'user_email',
  },

  {
    title: (
      <p className="table-header-text">
        {' '}
        Product <span> المنتج </span>
      </p>
    ),
    dataIndex: 'product',
  },
  {
    title: (
      <p className="table-header-text">
        {' '}
        image <span> الصورة </span>
      </p>
    ),
    dataIndex: 'product_image',
    render: (_, record) => {
      return (
        <div>
          <img height="40px" src={record.product_image} alt="Product Image" />
        </div>
      );
    },
  },
  {
    title: (
      <p className="table-header-text">
        {' '}
        Quantity <span> الكمية </span>
      </p>
    ),
    dataIndex: 'quantity',
  },
  {
    title: (
      <p className="table-header-text">
        {' '}
        date <span> التاريخ </span>
      </p>
    ),
    // dataIndex: 'date',
    render: (_, record) => (
      <p>{moment(record?.date).format('DD-MM-YYYY , hh:mm a')}</p>
    ),
  },
];

export const ProductRfq = () => {
  const [productList, setProductList] = useState([]);
  const dispatch = useDispatch();
  const { searchQueryObj } = useSearchQuery();
  const { paginationNumber } = useLocalPagination();
  const [loading, setLoading] = useState(false);

  const handleFetchProduct = useCallback(() => {
    const fd = new FormData();
    fd.append(
      'pagination_number',
      searchQueryObj?.pageSize || paginationNumber
    );
    fd.append('page', searchQueryObj?.page || 1);
    setLoading(true);
    Api.post('reports/product-rfq', fd).then(res => {
      setProductList(res.data.data);
      setLoading(false);
      dispatch(decrementDashbaordCounterAction(DB_PRODUCTRFQ_KEY));
    });
  }, [searchQueryObj.page, searchQueryObj.pageSize]);

  useEffect(() => {
    handleFetchProduct();
  }, [handleFetchProduct]);

  return (
    <div>
      <TableGrid
        title={
          <p>
            {' '}
            product RFQ <span> طلب سعر المنتج </span>
          </p>
        }
        columns={columns}
        dataSource={productList?.product_rfqs}
        loading={loading}
        pagination={productList?.pagination}
        // pagination={{ pageSize: 10, total: productList?.pagination?.total }}
        // defaultCurrent={10}
        // total={productList?.pagination?.total}
        // onChange={(pagination) => {
        //   let url = `reports/product-rfq?page=${pagination?.current}`;
        //   handleFetchProduct(url);
        // }}
      />
    </div>
  );
};
