import { message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { TableGrid } from '../../../Component/Table';
import useLocalPagination from '../../../hooks/useLocalPagination';
import useSearchQuery from '../../../hooks/useSearchQuery';
import Api from '../../../Network/ApiConfig';
import './style.scss';

export const SubscriptionHistory = () => {
  const [historyList, setHistoryList] = useState([]);
  const [historyListTotal, setHistoryListTotal] = useState([]);
  const [loading, setLoading] = useState(false);
  const { searchQueryObj } = useSearchQuery();
  const { paginationNumber } = useLocalPagination();

  const handleFetchHistory = useCallback(() => {
    const fd = new FormData();
    fd.append(
      'pagination_number',
      searchQueryObj?.pageSize || paginationNumber
    );
    fd.append('page', searchQueryObj?.page || 1);
    setLoading(true);
    Api.post('subscriptions/history', fd).then(res => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        setHistoryList(res.data.data?.supplier_history);
        setHistoryListTotal(res.data.data);
      }
      setLoading(false);
    });
  }, [searchQueryObj.page, searchQueryObj.pageSize]);

  useEffect(() => {
    handleFetchHistory();
  }, [handleFetchHistory]);

  const columns = [
    {
      title: 'DATE',
      dataIndex: 'endDate',
      sorter: {
        compare: (a, b) => a.endDate < b.endDate,
      },
      render: (_, record) => {
        return <p> {record?.endDate ? record?.endDate : '-'} </p>;
      },
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      sorter: {
        compare: (a, b) => a.type < b.type || a.type > b.type,
      },
      render: (_, record) => {
        return <p> {record?.type ? record?.type : '-'} </p>;
      },
    },
    {
      title: 'RANK',
      dataIndex: 'rank',
      render: (_, record) => {
        return <p> {record?.rank ? record?.rank : '-'} </p>;
      },
      sorter: {
        compare: (a, b) => a.rank < b.rank || b.rank < a.rank,
      },
    },
    {
      title: 'TENDER',
      dataIndex: 'tenderUnlock',
      render: (_, record) => {
        return <p> {record?.tenderUnlock ? record?.tenderUnlock : '-'} </p>;
      },
      sorter: {
        compare: (a, b) =>
          a.tenderUnlock < b.tenderUnlock || a.tenderUnlock > b.tenderUnlock,
      },
    },
    {
      title: 'PROMOTION',
      dataIndex: 'promotion',
      render: (_, record) => {
        return <p> {record?.promotion ? record?.promotion : '-'} </p>;
      },
      sorter: {
        compare: (a, b) =>
          a.promotion < b.promotion || a.promotion > b.promotion,
      },
    },
    {
      title: 'CATEGORY',
      dataIndex: 'categoryNo',
      render: (_, record) => {
        return <p> {record?.categoryNo ? record?.categoryNo : '-'} </p>;
      },
      sorter: {
        compare: (a, b) =>
          a.categoryNo < b.categoryNo || a.categoryNo > b.categoryNo,
      },
    },
    {
      title: 'PRODUCT',
      dataIndex: 'productNo',
      render: (_, record) => {
        return <p> {record?.productNo ? record?.productNo : '-'} </p>;
      },
      sorter: {
        compare: (a, b) =>
          a.productNo < b.productNo || a.productNo > b.productNo,
      },
    },
    {
      title: 'EMAIL',
      dataIndex: 'massEmail',
      render: (_, record) => {
        return <p> {record?.massEmail ? record?.massEmail : '-'} </p>;
      },
      sorter: {
        compare: (a, b) =>
          a.massEmail < b.massEmail || a.massEmail > b.massEmail,
      },
    },
    {
      title: 'FB',
      dataIndex: 'facebookBudget',
      render: (_, record) => {
        return <p> {record?.facebookBudget ? record?.facebookBudget : '-'} </p>;
      },
      sorter: {
        compare: (a, b) =>
          a.facebookBudget < b.facebookBudget ||
          a.facebookBudget > b.facebookBudget,
      },
    },
    {
      title: 'G',
      dataIndex: 'googleBudget',
      render: (_, record) => {
        return <p> {record?.googleBudget ? record?.googleBudget : '-'} </p>;
      },
      sorter: {
        compare: (a, b) =>
          a.googleBudget < b.googleBudget || a.googleBudget > b.googleBudget,
      },
    },
    {
      title: 'E-DATE',
      dataIndex: 'endDate',
      render: (_, record) => {
        return <p> {record?.endDate ? record?.endDate : '-'} </p>;
      },
      sorter: {
        compare: (a, b) => a.endDate < b.endDate || a.endDate > b.endDate,
      },
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  return (
    <div>
      <TableGrid
        title={
          <p>
            {' '}
            History <span> تفاصيل المعاملات </span>
          </p>
        }
        columns={columns}
        dataSource={historyList}
        rowKey={record => record.id}
        expandable={{
          expandedRowRender: record => (
            <p style={{ margin: 0 }}>
              <span>Description: </span>
              {record.desc}
            </p>
          ),
        }}
        loading={loading}
        handleTableChange={handleTableChange}
        pagination={historyListTotal?.pagination}
        // onChange={(pagination) => {
        //   let url = `subscriptions/history?page=${pagination?.current}`
        //   handleFetchHistory(url)
        // }}
        // pagination={{ pageSize: 10 }}
        // defaultCurrent={10}
        // total={historyListTotal?.pagination?.total}
      />
    </div>
  );
};
