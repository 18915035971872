import React, { Component, useEffect } from 'react';
import ProtectedRoute from './ProtectedRoute';
import { Route, useHistory, withRouter } from 'react-router-dom';
import login from './pages/Login/login';
// import Header from "./components/Header/Header";
// import Footer from "./components/Footer/Footer";
// import ResNavBar from "./components/ResNavBar/ResNavBar";
import { Order } from './pages/Orders/index';
import { Dashboard } from './pages/Dashboard/index';
import { ProductRfq } from './pages/profile/ProductRFQ/index';
import { OrderDetails } from './pages/Orders/OrderDetails/index';
import { SubscriptionHistory } from './pages/subscription/History/index';
import { MySubscription } from './pages/subscription/mySubscription/index';
import { UserGroup } from './pages/profile/UserGroup/index';
import { PersonalInfo } from './pages/profile/PersonalInfo/index';
import { UserLeads } from './pages/Reports/UserLeads/index';
import { AllProduct } from './pages/profile/AllProducts/index';
import { AddProduct } from './pages/profile/AllProducts/AddProduct/index';
import { SubCategories } from './pages/profile/subCategories/index';
import { AssignSubCategories } from './pages/profile/subCategories/AssignCategories/index';
import { ExtrasPurchase } from './pages/subscription/Extras';
// import { PriceListPage } from './pages/profile/PriceList';
import { PriceList } from './pages/profile/PriceList/Index';
import { CompanyInfo } from './pages/profile/CompanyInfo';
import { NewsFeed } from './pages/profile/NewsFeed';
import { Message } from './pages/Messages';
import { SalesTeams } from './pages/profile/SalesTeam';
import { AddEditSalesTeam } from './pages/profile/SalesTeam/addEditSalesTeam';
import { CallBack } from './pages/Reports/CallBack';
import { InfoViewed } from './pages/Reports/InfoViewed';
import { MyHistorySub } from './pages/subscription/MyHistorySub';
import { GeneralRFQ } from './pages/profile/GeneralRFQ/index';
import { Tenders } from './pages/Tenders/Tenders';
import ForgetPassword from './pages/ForgetPassword/ForgetPassword';
import { AllLeads } from './pages/Reports/AllLeads/AllLeads';
import NoSubscription from './pages/NoSubscription/NoSubscription';
// import Plans from './pages/Plans/Plans';

const Routes = () => {
  return (
    <>
      <div className="page-wrapper app-wrapper">
        {/* <Header />  */}
        <div className="page-main">
          <div className="container">
            {/* <Route path='/supplier=:id' component={LoginSupplierById} /> */}
            {/* <Route exact path="/plans" component={Plans} /> */}
            <Route exact path="/login" component={login} />
            <Route exact path="/forget-password" component={ForgetPassword} />
            <ProtectedRoute exact path="/" component={Dashboard} />
            <ProtectedRoute exact path="/order" component={Order} />
            <ProtectedRoute exact path="/test" component={NoSubscription} />

            <ProtectedRoute exact path="/dashboard" component={Dashboard} />
            <ProtectedRoute exact path="/ordersDetails" component={OrderDetails} />
            {/* subscription Tab */}
            <ProtectedRoute exact path="/subscription/history" component={SubscriptionHistory} />
            <ProtectedRoute exact path="/subscription/mySubscription" component={MySubscription} />
            <ProtectedRoute exact path="/subscription/purchase" component={ExtrasPurchase} />
            <ProtectedRoute exact path="/subscription/my-history-sub" component={MyHistorySub} />
            {/* Profile Tab */}
            <ProtectedRoute exact path="/profile/userGroup" component={UserGroup} />
            <ProtectedRoute exact path="/profile/PersonalInfo" component={PersonalInfo} />
            <ProtectedRoute exact path="/profile/all-product" component={AllProduct} />
            <ProtectedRoute exact path="/profile/sub-categories" component={SubCategories} />
            <ProtectedRoute exact path="/profile/sub-categories/assign" component={AssignSubCategories} />
            <ProtectedRoute exact path="/profile/product-add" component={AddProduct} />
            <ProtectedRoute exact path="/profile/priceList" component={PriceList} />
            <ProtectedRoute exact path="/profile/company-info" component={CompanyInfo} />
            <ProtectedRoute exact path="/profile/newsFeed" component={NewsFeed} />
            <ProtectedRoute exact path="/profile/sales-team" component={SalesTeams} />
            <ProtectedRoute exact path="/profile/add-sales-team" component={AddEditSalesTeam} />
            {/* reports Tab */}
            <ProtectedRoute exact path="/reports/product" component={ProductRfq} />
            <ProtectedRoute exact path="/reports/generalRfq" component={GeneralRFQ} />
            <ProtectedRoute exact path="/reports/userLeads" component={UserLeads} />
            <ProtectedRoute exact path="/reports/allLeads" component={AllLeads} />
            <ProtectedRoute exact path="/reports/callBack" component={CallBack} />
            <ProtectedRoute exact path="/reports/info-view" component={InfoViewed} />

            <ProtectedRoute exact path="/messages" component={Message} />
            <ProtectedRoute exact path="/tenders" component={Tenders} />
          </div>
        </div>
      </div>
    </>
  );
  // }
};

export default withRouter(Routes);
