/* eslint-disable jsx-a11y/anchor-is-valid */
import { Pagination } from 'antd';
import React from 'react';
import { css } from '@emotion/css';
// import arrL from 'assets/icons/chv-l.svg';
// import arrR from 'assets/icons/chv-r.svg';
import useSearchQuery from '../../hooks/useSearchQuery';

function OwnPagination({
  // total,
  // pageSize,
  // justify,
  // style,
  // current,
  // onChange,
  pagination,
  jumbBtns,
  hideOnSinglePage,
  className,
  ...rest
}) {
  console.log('pagination', pagination);
  const OwnPaginationStyles = css`
    margin: 4rem 0;
    direction: ltr;
    li {
      /* width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      background: #d9d9d9; */
      /* display: flex;
      align-items: center;
      justify-content: center; */

      /* font-weight: 500;
      font-size: 1.5rem;
      line-height: 1.8;
      text-align: center;
      color: #000000; */

      .btn {
        /* font-size: 1.6rem;
        line-height: 2;
        text-align: center; */
        /* color: #000000; */

        img {
          width: 1.6rem;
        }
      }

      &.ant-pagination-item-active {
        /* background: #058b92;
        a {
          color: #fff;
        } */
      }
      &.ant-pagination-options {
        .ant-select-selector {
          height: 37px !important;
          .ant-select-selection-item {
            display: flex;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .ant-select-arrow {
        left: auto;
        right: 11px !important;
        font-size: 12px;
      }
    }
  `;
  // const itemRender = (_, type, originalElement) => {
  //   if (type === 'prev') {
  //     return (
  //       <a className="btn">
  //         <img src={arrL} alt="arrow left" />
  //       </a>
  //     );
  //   }

  //   if (type === 'next') {
  //     return (
  //       <a className="btn">
  //         <img src={arrR} alt="arrow right" />
  //       </a>
  //     );
  //   }

  //   return originalElement;
  // };

  function itemRender(current, type, originalElement) {
    if (type === 'prev') return <a>Previous</a>;

    if (type === 'next') return <a>Next</a>;

    return originalElement;
  }
  // console.log((pagination.total = 300))
  const { searchQueryObj, setSearchQuery } = useSearchQuery();
  const onChange = page => {
    console.log('onChange  page', page);
    const queryObj = { ...searchQueryObj };
    queryObj.page = page;
    setSearchQuery(queryObj);
    return null;
  };
  return (
    <Pagination
      // total={total}
      // className={cls(OwnPaginationStyles, className)}
      // current={current || 1}
      // pageSize={pageSize || 10}
      {...pagination}
      pageSize={pagination?.size || 10}
      itemRender={itemRender}
      onChange={onChange}
      hideOnSinglePage={hideOnSinglePage ?? true}
      // showPrevNextJumpers
      // pageSizeOptions={[20, 40]}
      className={OwnPaginationStyles}
      {...rest}
    />
  );
}

export default OwnPagination;

// const [pagination, setPagination] = useState({});
// const [curPage, setCurPage] = useState(1);
// const onChangePage = (cur, pageSize) => {
//   setCurPage(cur);
//   fetchList(cur);
// };
// <OwnPagination
//   total={pagination.total}
//   pageSize={pagination.size}
//   current={curPage}
//   onChange={onChangePage}
//   justify="end"
// />;
