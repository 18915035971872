import React, { useCallback, useEffect, useState } from 'react';
import { Button, Tag, Modal, message, Form, Input } from 'antd';
import { TableGrid } from '../../../Component/Table';
import './style.scss';
import Api from '../../../Network/ApiConfig';
import { StarFilled, DeleteFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useSearchQuery from '../../../hooks/useSearchQuery';
import useLocalPagination from '../../../hooks/useLocalPagination';

const { confirm } = Modal;

export const SubCategories = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [topSuppliers, settopSuppliers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isRankVisible, setisRankVisible] = useState(false);
  const [isOrderVisible, setisOrderVisible] = useState(false);
  const [categorySelected, setcategorySelected] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();
  const { searchQueryObj } = useSearchQuery();
  const { paginationNumber } = useLocalPagination();

  const mutateCategoriesListHelper = (id, key, func) => {
    const changedCategoryIdx = categoryList?.categories?.findIndex(product => product.id === id);
    const newCategoriesList = {
      ...categoryList,
      categories: [...categoryList.categories],
    };
    newCategoriesList.categories[changedCategoryIdx] = {
      ...newCategoriesList.categories[changedCategoryIdx],
      [key]: func(newCategoriesList.categories[changedCategoryIdx], key),
    };
    return newCategoriesList;
  };

  const fetchCategoriesList = useCallback(() => {
    const fd = new FormData();
    fd.append('pagination_number', searchQueryObj?.pageSize || paginationNumber);
    fd.append('page', searchQueryObj?.page || 1);
    setLoading(true);
    Api.post('categories/assigned', fd).then(res => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        setCategoryList(res.data.data);
      }

      setLoading(false);
    });
  }, [searchQueryObj.page, searchQueryObj.pageSize]);

  useEffect(() => {
    fetchCategoriesList();
  }, [fetchCategoriesList]);

  const fetchTopSuppliers = data => {
    console.log(data);
    // setLoading(true);
    let body = new FormData();
    body.append('categoryId', data?.id);
    Api.post('categories/top/ranked', body).then(res => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) settopSuppliers(res?.data?.data?.top_ranked || []);
      setLoading(false);
    });
  };

  const handelChangeVisibility = data => {
    setLoading(true);
    let body = new FormData();
    body.append('categoryId', data?.id);
    Api.post('categories/show/hide', body).then(res => {
      setLoading(false);
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        message.success(res.data.message);
        const newCategoriesList = mutateCategoriesListHelper(data?.id, 'visibility', category => (category.visibility === 1 ? 0 : 1));
        setCategoryList(newCategoriesList);
        // fetchCategoriesList();
      }
    });
  };

  const handleCategoryRank = values => {
    setLoading(true);
    let body = new FormData();
    body.append('categoryId', categorySelected?.id);
    body.append('rank', values?.rank);
    Api.post('categories/assign/rank', body).then(
      res => {
        console.log(res);
        setLoading(false);
        if (res.data.validation.length > 0) {
          message.error(res.data.validation[0]);
        }
        if (res.data.code === 101) {
          message.error(res.data.message);
        }
        if (res.data.code === 200) {
          message.success(res.data.message);
          setisRankVisible(false);
          fetchCategoriesList();
        }
      },
      err => {
        console.log(err);
        // message.error()
      }
    );
  };

  const handleCategoryOrder = values => {
    setLoading(true);
    let body = new FormData();
    body.append('categoryId', categorySelected?.id);
    body.append('order', values?.order);
    Api.post('categories/assign/order', body).then(
      res => {
        console.log(res);
        setLoading(false);
        if (res.data.validation.length > 0) {
          message.error(res.data.validation[0]);
        }
        // if (res.data.code === 101) {
        // 	message.error(res.data.message);
        // }
        if (res.data.code === 200) {
          message.success(res.data.message);
          setisOrderVisible(false);
          fetchCategoriesList();
        }
      },
      err => {
        console.log(err);
        // message.error()
      }
    );
  };

  const handleDeleteSubCategories = record => {
    let body = {
      categoryId: record.id,
      confirm: 1,
    };

    confirm({
      title: `Delete This Category?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        Api.post('categories/delete', body).then(res => {
          if (res.data.validation.length > 0) {
            message.error(res.data.validation[0]);
          }

          if (res.data.code === 200) {
            message.success(res.data.message);
            fetchCategoriesList();
          }
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const columns = [
    {
      title: (
        <p className="table-header-text">
          {' '}
          Rank <span>ترتيب الظهور علي الموقع</span>{' '}
        </p>
      ),
      dataIndex: 'rank',
      render: (_, rec) => {
        return (
          <p
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setcategorySelected(rec);
              fetchTopSuppliers(rec);
              setisRankVisible(true);
            }}>
            {' '}
            {rec.rank} <StarFilled style={{ color: '#ff9901', fontSize: '14px' }} />{' '}
          </p>
        );
      },
    },
    {
      title: (
        <p className="table-header-text">
          {' '}
          Name <span> الاسم </span>
        </p>
      ),
      dataIndex: 'name_en',
      render: (_, rec) => {
        return (
          <p>
            {' '}
            {rec.name_en} - {rec.name_ar}{' '}
          </p>
        );
      },
    },
    {
      title: (
        <p className="table-header-text">
          {' '}
          category <span> القسم </span>
        </p>
      ),
      dataIndex: 'name_en',
      render: (_, rec) => {
        return <p>{rec.parent}</p>;
      },
    },
    {
      title: (
        <p className="table-header-text">
          {' '}
          Order <span>ترتيب الظهور علي الحساب</span>{' '}
        </p>
      ),
      dataIndex: 'order',
      render: (_, rec) => {
        return (
          <p
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setcategorySelected(rec);
              setisOrderVisible(true);
            }}>
            {' '}
            {rec.order} <StarFilled style={{ color: '#ff9901', fontSize: '14px' }} />{' '}
          </p>
        );
      },
    },
    {
      title: (
        <p className="table-header-text">
          {' '}
          Products <span> المنتجات </span>
        </p>
      ),
      dataIndex: 'products',
    },
    {
      title: (
        <p className="table-header-text">
          {' '}
          Visibility <span> الحالة </span>
        </p>
      ),
      dataIndex: 'visibility',
      render: (_, rec) => {
        return (
          <div>
            <Tag
              onClick={() => handelChangeVisibility(rec)}
              className="table-tag"
              style={{
                cursor: 'pointer',
                background: rec.visibility === 1 ? '#1cb5e0' : '#fc4242',
              }}>
              {rec.visibility === 1 ? 'visible - ظاهر' : 'Invisible - مخفي'}
            </Tag>
          </div>
        );
      },
    },
    {
      title: (
        <p className="table-header-text">
          {' '}
          Actions <span> افعال </span>
        </p>
      ),
      dataIndex: 'address',
      render: (_, record) => {
        return <DeleteFilled style={{ color: '#fc4242', fontSize: '18px' }} onClick={() => handleDeleteSubCategories(record)} />;
      },
    },
  ];

  return (
    <div className="product__wrapper">
      <div className="product__wrapper--header">
        <p style={{ fontWeight: 'bold' }} className="inter primary-text ">
          Category: {categoryList?.category_count}
        </p>
        <p style={{ fontWeight: 'bold' }} className="inter primary-text ">
          Rank: {categoryList?.rank}
        </p>
      </div>
      <TableGrid
        title={
          <div className="table__filter">
            <p className="table__title">
              {' '}
              Categories <span> الاقسام </span>
            </p>

            <div className="product__btn">
              <Button onClick={() => history.push('/profile/sub-categories/assign')} className="primary">
                New Subcategory قسم جديد
              </Button>
            </div>
          </div>
        }
        columns={columns}
        dataSource={categoryList?.categories}
        loading={loading}
        pagination={categoryList?.pagination}
        // defaultCurrent={10}
        // pagination={{ pageSize: 10, total: categoryList?.category_count }}
        // total={categoryList?.category_count}
        // onChange={(pagination) => {
        //   let url = `categories/assigned?page=${pagination?.current}`;
        //   fetchCategoriesList(url);
        // }}
      />
      <Modal
        className="add__record--modal"
        footer={null}
        title="تعديل المنتج"
        visible={isOrderVisible}
        onOk={() => setisOrderVisible(false)}
        onCancel={() => setisOrderVisible(false)}>
        <Form
          form={form}
          name="personalInfo"
          className="form__wrapper"
          onFinish={handleCategoryOrder}
          // onFinishFailed={onFinishFailed}
          autoComplete="off">
          <Form.Item
            label="اضف قيمة"
            name="order"
            rules={[
              { required: true, message: 'This field is required!' },
              { pattern: /^\d+$/, message: 'must be numbers only!' },
            ]}>
            <Input type="number" />
          </Form.Item>

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              حفظ
              <span>&#10004;</span>
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        className="add__record--modal"
        footer={null}
        title="تعديل القسم"
        visible={isRankVisible}
        onOk={() => setisRankVisible(false)}
        onCancel={() => setisRankVisible(false)}>
        <Form
          form={form}
          name="personalInfo"
          className="form__wrapper"
          onFinish={handleCategoryRank}
          // onFinishFailed={onFinishFailed}
          autoComplete="off">
          <div className="top-supplier-list-holder">
            <h4 className="list-title">افضل 5 موردين</h4>
            <div className="list-holder">
              {topSuppliers?.map((s, idx) => (
                <div className="supplier-row-holder" key={s?.id}>
                  <p className="supplier-title">
                    {idx + 1}-{s?.company_name}
                  </p>
                  <Button size="small" type="primary">
                    Rank : {s?.rank}
                  </Button>
                </div>
              ))}
            </div>
          </div>
          <Form.Item
            label="اضف قيمة"
            name="rank"
            rules={[
              { required: true, message: 'This field is required!' },
              { pattern: /^\d+$/, message: 'must be numbers only!' },
            ]}>
            <Input type="number" />
          </Form.Item>

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              حفظ
              <span>&#10004;</span>
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
