import { css } from '@emotion/css';
import { Select } from 'antd';
import useLocalPagination from '../../hooks/useLocalPagination';
import useSearchQuery from '../../hooks/useSearchQuery';

const { Option } = Select;
function TableSelectPagin() {
  const TableSelectPaginStyles = css`
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0 10px;

    p {
      font-size: 14px;
      font-weight: normal;
      color: #292d32;
    }

    span {
      font-size: 14px;
      font-weight: normal;
      color: #292d32;
      display: inline-block;
    }
    .ant-select {
      display: flex;
      align-items: center;
      padding: 0 5px;
      .ant-select-selector {
        min-width: 64px;
        padding: 0 7px;

        .ant-select-selection-item {
          padding: 0;
        }
      }
      .ant-select-arrow {
      }
    }
  `;
  const { searchQueryObj, setSearchQuery } = useSearchQuery();
  const { paginationNumber, setPaginationNumber } = useLocalPagination();

  const onChangePaginationNum = num => {
    const queryObj = { ...searchQueryObj };
    queryObj.page = 1;
    queryObj.pageSize = num;
    setSearchQuery(queryObj);
    setPaginationNumber(num);
  };

  return (
    <div className={TableSelectPaginStyles}>
      <p>Show</p>
      <Select
        defaultValue={paginationNumber}
        placeholder="Lead status"
        onChange={onChangePaginationNum}
      >
        <Option value={10}> 10 </Option>
        <Option value={20}> 20 </Option>
        <Option value={40}> 40 </Option>
        <Option value={80}> 80 </Option>
      </Select>
      <span>entries</span>
    </div>
  );
}

export default TableSelectPagin;
