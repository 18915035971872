import { message } from 'antd';
import { useEffect, useState } from 'react';
import Api from '../../Network/ApiConfig';

export const useUnitList = () => {
  const [unitList, setUnitList] = useState([]);
  const [loading, setLoading] = useState(false);

  const http = () => {
    Api.get('products/unit/measurements')
      .then(res => {
        if (res.data.validation.length > 0) {
          message.error(res.data.validation[0]);
        }
        if (res.data.code === 200) {
          setUnitList(res.data?.data?.unit_measurements);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log('Api.get  err:', err);
        setLoading(false);
      });
  };
  useEffect(() => {
    http();
  }, []);

  return { loading, unitList };
};
