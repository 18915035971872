import React, { useCallback, useEffect, useState } from 'react';
import { TableGrid } from '../../../Component/Table';
import './style.scss';
import Api from '../../../Network/ApiConfig';
import { Button, Form, message, Modal } from 'antd';
import Input from 'antd/lib/input/Input';
import useSearchQuery from '../../../hooks/useSearchQuery';
import useLocalPagination from '../../../hooks/useLocalPagination';

export const UserGroup = () => {
  const [groupList, setGroupList] = useState([]);
  const [groupListFiltered, setGroupListFiltered] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentAmountId, setCurrentAmountId] = useState(null); // id for the current group price amount change modal
  const [currentAmountName, setCurrentAmountName] = useState(null); // id for the current group price amount change modal
  const [loading, setLoading] = useState(false);
  const { searchQueryObj } = useSearchQuery();
  const { paginationNumber } = useLocalPagination();

  const handleProductAmount = values => {
    const { amount } = values;
    const body = new FormData();
    body.append('groupId', currentAmountId);
    body.append('minimum_order_amount', amount);
    body.append('name', currentAmountName);
    setLoading(true);
    Api.post('groups/update', body).then(res => {
      if (res.data.code === 200) {
        const changedAmountIndex = groupList.findIndex(
          el => el.id === currentAmountId
        );
        const newGroupList = [...groupList];
        newGroupList[changedAmountIndex].minimum_order_amount = amount;
        setGroupList(newGroupList);
        setIsModalVisible(false);
      }
      setLoading(false);
    });
  };

  const fetchGroupList = useCallback(() => {
    const fd = new FormData();
    fd.append(
      'pagination_number',
      searchQueryObj?.pageSize || paginationNumber
    );
    fd.append('page', searchQueryObj?.page || 1);

    Api.post('groups/get', fd).then(res => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        setGroupList(res.data.data.my_groups);
        setGroupListFiltered(res.data.data);
      }
    });
  }, [searchQueryObj.page, searchQueryObj.pageSize]);

  useEffect(() => {
    fetchGroupList();
  }, [fetchGroupList]);

  const columns = [
    {
      title: (
        <p className="table-header-text">
          {' '}
          User group name <span> الاسم </span>
        </p>
      ),
      dataIndex: 'name',
    },
    // {
    //   title: <p className='table-header-text'> Products <span> المنتجات </span></p>,
    //   dataIndex: 'products',
    // },
    {
      title: (
        <p className="table-header-text">
          {' '}
          Minimum order amount <span> الحد الادني للطلب </span>
        </p>
      ),
      // dataIndex: 'minimum_order_amount',
      render: (_, record) => {
        return (
          <p
            className="link"
            onClick={() => {
              setIsModalVisible(true);
              setCurrentAmountId(record.id);
              setCurrentAmountName(record.name);
            }}
          >
            {record.minimum_order_amount} LE
          </p>
        );
      },
    },
  ];

  const handleFilterList = value => {
    if (!!value) {
      let salesFiltered = groupListFiltered?.my_groups?.filter(i =>
        i.name.toLowerCase().includes(value.toLowerCase())
      );
      setGroupList(salesFiltered);
    } else {
      fetchGroupList();
    }
  };

  return (
    <div>
      <TableGrid
        title={
          <div className="table__filter">
            <p className="bold">
              {' '}
              User groups{' '}
              <span className="normal-font" style={{ marginLeft: '10px' }}>
                {' '}
                شريحة المشتريين{' '}
              </span>
            </p>
            <div className="search__list--input">
              <Input
                placeholder="Search For Name"
                onChange={e => handleFilterList(e.target.value)}
              />
            </div>
          </div>
        }
        columns={columns}
        dataSource={groupList}
        pagination={groupListFiltered?.pagination}
        // pageSize={10}
        // defaultCurrent={10}
        // total={groupListFiltered?.pagination?.total}
        // onChange={(pagination) => {
        //   let url = `groups/get?page=${pagination?.current}`;
        //   fetchGroupList(url);
        // }}
      />
      <Modal
        className="add__record--modal"
        footer={null}
        title="تعديل الحد الادني للطلب"
        destroyOnClose
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form
          name="personalInfow"
          className="form__wrapper"
          onFinish={handleProductAmount}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="اضف قيمة"
            name="amount"
            rules={[
              {
                required: true,
                message: 'This field is required!',
              },
              { pattern: /^\d+$/, message: 'must be numbers only!' },
            ]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              حفظ
              <span>&#10004;</span>
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
