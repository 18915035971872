import React, { useEffect, useState, useCallback } from 'react';
import { Input, message, Select, Tag } from 'antd';
import { TableGrid } from '../../Component/Table';
import './style.scss';
import { useHistory } from 'react-router-dom';
import Api from '../../Network/ApiConfig';
import { useDispatch } from 'react-redux';
import { decrementDashbaordCounterAction } from '../../redux/dashboard/dashboard.action';
import { DB_ORDERS_KEY } from '../../redux/dashboard/dashboard.type';
import TableSelectPagin from '../../Component/TableSelectPagin/TableSelectPagin';
import useSearchQuery from '../../hooks/useSearchQuery';
import useLocalPagination from '../../hooks/useLocalPagination';

const { Option } = Select;

export const Order = () => {
  const [ordersList, setOrderList] = useState([]);
  const [ordersListPaginate, setOrdersListPaginate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState([]);
  const dispatch = useDispatch();
  const { searchQueryObj } = useSearchQuery();
  const { paginationNumber } = useLocalPagination();

  const fetchOrderList = useCallback(() => {
    const fd = new FormData();
    fd.append(
      'pagination_number',
      searchQueryObj?.pageSize || paginationNumber
    );
    fd.append('page', searchQueryObj?.page || 1);
    setLoading(true);
    Api.post('orders', fd).then(res => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        setOrderList(res.data.data.orders);
        setOrdersListPaginate(res.data.data);
        dispatch(decrementDashbaordCounterAction(DB_ORDERS_KEY));
      }
      setLoading(false);
    });
  }, [searchQueryObj.page, searchQueryObj.pageSize]);

  useEffect(() => {
    fetchOrderList();
    handleGetStatusOrder();
  }, [fetchOrderList]);

  const handleShowOrderDetails = order => {
    Api.get(`orders/show?orderId=${order.id}`).then(res => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        history.push({
          pathname: '/ordersDetails',
          state: res.data.data.order_details,
        });
      }
    });
  };

  const handleGetStatusOrder = order => {
    Api.get(`orders/orderStatus`).then(res => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }

      if (res.data.code === 200) {
        setOrderStatus(res.data.data.orderStatus);
      }
    });
  };
  const handleCancelOrder = id => {
    let body = {
      orderId: id,
    };
    Api.post(`orders/cancelOrder`, body).then(res => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        message.success(res.data.message || 'successfully');
        fetchOrderList();
      }
    });
  };

  const handleChangeOrderStatus = (id, rec) => {
    let body = {
      orderId: rec.id,
      order_status_id: id,
    };
    Api.post(`orders/changeStatus`, body).then(res => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        message.success(res.data.message || 'successfully');
        fetchOrderList();
      }
    });
  };

  const history = useHistory();

  const columns = [
    {
      title: (
        <p className="table-header-text">
          {' '}
          <span>رقم الطلب</span>{' '}
        </p>
      ),
      dataIndex: 'id',
      render: (_, record) => (
        <a
          onClick={() => {
            history.push({ pathname: '/ordersDetails', state: record });
          }}
          href="/ordersDetails"
        >
          {' '}
          #{record.id}
        </a>
      ),
    },
    {
      title: (
        <p className="table-header-text">
          <span> المستخدم </span>
        </p>
      ),
      dataIndex: 'user_company_name',
    },
    {
      title: (
        <p className="table-header-text">
          {' '}
          <span> شريحة المستخدم </span>
        </p>
      ),
      dataIndex: 'user_type',
    },
    {
      title: (
        <p className="table-header-text">
          <span> الاجمالي </span>
        </p>
      ),
      dataIndex: 'total_amount',
      render: (_, record) => <span>{record.total_amount} LE </span>,
    },
    {
      title: (
        <p className="table-header-text">
          <span> حالة الطلب </span>
        </p>
      ),
      dataIndex: 'order_status',
      render: (_, record) => {
        return (
          <div>
            <Tag
              className={`order__status--tag ${record.order_status
                ?.split(' ')
                .join('')
                .toLowerCase()}`}
            >
              {' '}
              {record.order_status}{' '}
            </Tag>
          </div>
        );
      },
    },
    {
      title: (
        <p className="table-header-text">
          {' '}
          <span> تاريخ الطلب </span>
        </p>
      ),
      dataIndex: 'date_placed',
      render: (_, record) => {
        return (
          // <p> {moment(record?.date_placed)?.format('YYYY-MM-DD HH:mm:SS')} </p>
          <p> {record?.date_placed} </p>
        );
      },
    },
    {
      title: (
        <p className="table-header-text">
          {' '}
          <span> افعال </span>
        </p>
      ),
      dataIndex: 'address',
      render: (_, record) => {
        return (
          <div className="order__actions">
            <span
              onClick={() => {
                handleShowOrderDetails(record);
              }}
            >
              أظهار الطلب
            </span>
            <span
              style={{
                cursor:
                  record.order_status.toLowerCase() === 'delivered' ||
                  record.order_status === 'Is Cancelled'
                    ? 'not-allowed'
                    : 'pointer',
              }}
              onClick={() => {
                if (
                  record.order_status.toLowerCase() === 'delivered' ||
                  record.order_status === 'Is Cancelled'
                )
                  return;
                handleCancelOrder(record.id);
              }}
            >
              إلغاء الطلب
            </span>
          </div>
        );
      },
    },
    {
      title: (
        <p className="table-header-text">
          {' '}
          <span> تغيير الحالة </span>
        </p>
      ),
      dataIndex: 'address',
      render: (_, record) => {
        return (
          <Select
            className="order-status-select"
            value={
              record.order_status === 'Is Cancelled'
                ? 'Cancelled'
                : orderStatus?.find(i => i.name === record.order_status)?.id
            }
            placeholder="Lead status"
            disabled={
              record.order_status.toLowerCase() === 'delivered' ||
              record.order_status === 'Is Cancelled'
            }
            onChange={value => {
              handleChangeOrderStatus(value, record);
            }}
          >
            {orderStatus?.map((stat, idx) => (
              <Option key={idx} value={stat.id}>
                {' '}
                {stat.name}{' '}
              </Option>
            ))}
          </Select>
        );
      },
    },
  ];

  const handleFilterList = value => {
    if (!!value) {
      let salesFiltered = ordersListPaginate?.orders?.filter(i =>
        i.user_name.toLowerCase().includes(value.toLowerCase())
      );
      setOrderList(salesFiltered);
    } else {
      fetchOrderList();
    }
  };

  return (
    <div className="order__wrapper">
      <TableGrid
        title={
          <div className="table__filter">
            <p className="bold">
              <span style={{ margin: '0 10px', fontWeight: 'normal' }}>
                الطلبات{' '}
              </span>
            </p>
            {/* <div className='table__filter--count'>
              <p>Show</p>
              <Select
                defaultValue={defaultCurrentVal}
                placeholder='Lead status'
                className='align-left'
                onChange={(value) => {
                  setDefaultCurrentVal(value);
                  fetchOrderList(null, value);
                }}
              >
                <Option value={10}> 10 </Option>
                <Option value={20}> 20 </Option>
                <Option value={30}> 30 </Option>
                <Option value={40}> 40 </Option>
              </Select>
              <span>entries</span>
            </div> */}
            <TableSelectPagin />
            <div className="table__filter--count">
              <p>Filter orders by: </p>
              <Select
                defaultValue={'فلتر'}
                className="filter__by--order"
                onChange={value => {
                  if (value === 'all') fetchOrderList();
                  else if (value === 'Is Cancelled') {
                    let filterOrderList = ordersListPaginate?.orders?.filter(
                      i => i.order_status === value
                    );
                    setOrderList(filterOrderList);
                  } else {
                    let filterOrderList = ordersListPaginate?.orders?.filter(
                      i =>
                        i.order_status ===
                        orderStatus.find(i => i.id === value)?.name
                    );
                    setOrderList(filterOrderList);
                  }
                }}
              >
                <Option value={'all'}> All </Option>
                {orderStatus?.map((stat, idx) => (
                  <Option key={idx} value={stat.id}>
                    {' '}
                    {stat.name}{' '}
                  </Option>
                ))}
                <Option value={'Is Cancelled'}> Is Cancelled </Option>
              </Select>
            </div>
            <div className="search__list--input">
              <Input
                placeholder="بحث"
                onChange={e => handleFilterList(e.target.value)}
              />
            </div>
          </div>
        }
        columns={columns}
        loading={loading}
        dataSource={ordersList}
        pagination={ordersListPaginate?.pagination}
      />
    </div>
  );
};
