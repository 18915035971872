import constants from '../constants';
import useLocalStorage from './useLocalStorage';

const defaultPaginationNumber = 20;
function useLocalPagination() {
  const { paginationNumberKey, setPaginationNumber } = constants;
  const paginationNumber =
    useLocalStorage(paginationNumberKey) || defaultPaginationNumber;

  return { paginationNumber, setPaginationNumber };
}

export default useLocalPagination;
